import { useState, useCallback } from 'react'
import { useWireValue } from '@forminator/react-wire'
import { useQuery } from '@tanstack/react-query'
import * as store from '$store'
import * as actions from '$actions'
import dayjs from 'dayjs'
import useOrgsSearch from '@hook/useOrgsSearch'
import { createSyntheticDownload, arrayToCsvBlob } from '@utils'

const ORG_NONE = { id: null, name: 'None' }

const ATRUsageViewModel = () => {
    
    const [selectedOrg, setSelectedOrg] = useState(null)
    const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
    const [orderBy, setOrderBy] = useState('createdAt')
    const [orderDirection, setOrderDirection] = useState('DESC')
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(50)
    
    const isFetchingOrgs = useWireValue(store.isFetchingOrgs)
    
    const orgsSearch = useOrgsSearch({ keepPreviousData: true })
    
    const {
        data: billableEvents,
        error: billableEventsError,
        isLoading: billableEventsIsLoading,
        refetch: fetchBillableEvents,
    } = useQuery({
        queryKey: ['billableEvents', selectedOrg, startDate, endDate, orderBy, orderDirection, offset, limit],
        queryFn: () => actions.fetchAtrBillableEvents(
            selectedOrg, startDate, endDate, orderBy, orderDirection, offset, limit),
        retry: 0,
    })
    
    const downloadBillableEventsReport = useCallback(async () => {
        
        const fileName = [
            selectedOrg?.name?.replace(/\s+/g, '_') ?? 'No_Organization',
            'ATR_Billable_Events',
            dayjs(startDate).format('YYYY-MM-DD'),
            'to',
            dayjs(endDate).format('YYYY-MM-DD'),
        ].join('-') + '.csv'
        
        const type = 'application/csv'
        const blob = arrayToCsvBlob(billableEvents.rows, type, true, (key, value) => {
            
            if (key === 'organization')
                return value?.name ?? 'No_Organization'
            
            return value
            
        })
        
        createSyntheticDownload(blob, type, fileName)
        
    }, [billableEvents, startDate, endDate])
    
    return {
        
        // Constants
        ORG_NONE,
        
        // State
        selectedOrg,
        setSelectedOrg,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        orderBy,
        setOrderBy,
        orderDirection,
        setOrderDirection,
        offset,
        setOffset,
        limit,
        setLimit,
        
        // Global State
        isFetchingOrgs,
        
        // Memos
        
        // Queries
        billableEvents,
        billableEventsError,
        billableEventsIsLoading,
        fetchBillableEvents,
        
        // Hooks
        orgsSearch,
        
        // Methods
        downloadBillableEventsReport,
        
    }
    
}

export default ATRUsageViewModel
