import CustomModal, { ModalBody, ModalFooter, ModalHeader } from '@/components/shared/CustomModal'
import { useEffect, useMemo, useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import * as actions from '$actions'
import cn from 'classnames'
import { MdAdd } from 'react-icons/md'
import { toast } from 'react-toastify'
import * as $store from '@store'
import { useWireValue } from '@forminator/react-wire'
import { Selectbox } from '@/components/shared/Selectbox'

const PresetInstructionAndDefinitionModal = (
    {
        showPresetInstructionDefinitionModal,
        setShowPresetInstructionDefinitionModal,
        system,
        presetId,
    },
) => {
    const remoteConfig = useWireValue($store.remoteConfig)
    
    const [instruction, setInstruction] = useState('')
    const [definitions, setDefinitions] = useState([{ key: '', definition: '' }])
    const [preset, setPreset] = useState({})
    
    const models = useMemo(() => {
        if (!remoteConfig.modelsPerSystem?.length) return []
        
        return remoteConfig.modelsPerSystem.filter(model => model.systems.some(s => s === system))
    }, [remoteConfig.modelsPerSystem, system])
    
    const modelOption = preset ? {
        value: preset.id,
        label: preset.modelName,
    } : null
    
    const handleDefinitionChange = (idx, definition, key, value) => {
        const newDefinitions = [...definitions]
        
        newDefinitions[idx] = {
            ...definition,
            [key]: value,
        }
        setDefinitions(newDefinitions)
    }
    
    const deleteDefinition = async (definitionId, idx) => {
        if (definitionId) {
            await actions.deletePresetDefinition(definitionId)
            setDefinitions(definitions.filter(def => def.id !== definitionId))
        } else {
            const newDefinitions = [...definitions]
            
            newDefinitions.splice(idx, 1)
            setDefinitions(newDefinitions)
        }
    }
    
    const createDefinition = async () => {
        const savedDefinitions = await actions.savePresetInstructionAndDefinitions(
            instruction, definitions, presetId)
        
        setDefinitions(savedDefinitions)
        
        toast.success('Instructions and Definitions updated')
        
        setShowPresetInstructionDefinitionModal(false)
    }
    
    const addNewDefinition = () => {
        setDefinitions([...definitions, { key: '', definition: '' }])
    }
    
    const saveModelToPreset = model => {
        actions.saveModel(presetId, model, 'preset')
    }
    
    useEffect(() => {
        if (!presetId) return
        
        actions.findPresetById(presetId).then(data => {
            setPreset(data)
        })
        
        actions.findPresetInstructionAndDefinitions(presetId).then(data => {
            
            setInstruction(data.preset.instructions || '')
            setDefinitions(data.presetDefinitions.length ? data.presetDefinitions : [{ key: '', definition: '' }])
        })
        
    }, [])
    
    return (
        
        <CustomModal
            open={showPresetInstructionDefinitionModal}
            modalHandler={() => setShowPresetInstructionDefinitionModal(false)}
            className="max-w-[95vw] ">
            <ModalHeader>
                Edit Instructions/Definitions
            </ModalHeader>
            <ModalBody>
                <div className="mb-2">
                    <div>
                        <label htmlFor="models">
                            Model
                        </label>
                        <Selectbox
                            className="w-[255px]"
                            handleChange={(ev, value) => {
                                saveModelToPreset(value.value)
                            }}
                            name="models"
                            option={modelOption}
                            options={models.map(dt => (
                                {
                                    value: dt.id,
                                    key: dt.id,
                                    label: dt.label,
                                }))} />
                    
                    </div>
                </div>
                <div className="flex flex-row w-full gap-2 max-h-[calc(100vh-200px)]">
                    <div className={cn('flex flex-col gap-4', {
                        'w-full': system !== 'value_extractor',
                        'flex-1': system === 'value_extractor',
                    })}>
                        <label htmlFor="instruction">Instructions</label>
                        <textarea
                            id="instruction"
                            type="textarea"
                            className="input input-bordered w-full h-[calc(100vh-200px)]"
                            value={instruction}
                            onChange={e => setInstruction(e.target.value)}></textarea>
                    </div>
                    
                    {system === 'value_extractor' && (
                        <div className="flex-1 ">
                            <div className="flex flex-row justify-between w-full gap-4 mb-1">
                                <label htmlFor="definitions"> Definitions </label>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => addNewDefinition()}> <MdAdd /> Add Definition </button>
                            </div>
                            
                            <div className="overflow-auto h-[calc(100vh-240px)]">
                                {definitions.map((definition, idx) => {
                                    return (
                                        <div key={definition.id} className="p-4 mb-2 rounded bg-base-100">
                                            <div className="flex justify-around gap-4">
                                                <div className="flex flex-col w-4/12">
                                                    <label htmlFor="vname"> Value Name </label>
                                                    <input
                                                        id="vname"
                                                        type="text"
                                                        className="w-full input input-bordered"
                                                        value={definition.key}
                                                        onChange={e => handleDefinitionChange(
                                                            idx, definition, 'key', e.target.value)} />
                                                </div>
                                                <div className="flex flex-col self-center w-8/12">
                                                    <label htmlFor="def"> Definition </label>
                                                    <textarea
                                                        id="def"
                                                        className="w-full h-20 input input-bordered"
                                                        value={definition.definition}
                                                        onChange={e => handleDefinitionChange(
                                                            idx, definition, 'definition', e.target.value)} />
                                                </div>
                                                <button
                                                    onClick={() => {
                                                        deleteDefinition(definition.id, idx)
                                                    }}
                                                    className="self-start text-red-400
                                                        cursor-pointer hover:text-red-600">
                                                    <FaTrash />
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                <button
                    className="btn btn-primary btn-outline"
                    onClick={() => setShowPresetInstructionDefinitionModal(false)}>
                    Cancel
                </button>
                <button
                    className="btn btn-primary"
                    onClick={() => createDefinition()}>
                    Update
                </button>
            </ModalFooter>
        </CustomModal>
        
    )
}

export default PresetInstructionAndDefinitionModal
