import { Link, Outlet } from 'react-router-dom'
import cn from 'classnames'

const links = [
    ['Home', '/dashboard/tracking'],
    ['User Actions', '/dashboard/tracking/user-tracking'],
    ['ATR Usage', '/dashboard/tracking/atr-usage'],
]

const Tracking = () => {
    
    return (
        
        <div className="Tracking p-4 w-full h-auto">
            
            <h4 className="text-base">Tracking</h4>
            
            <nav className="flex items-center content-center gap-4 w-full bg-base-200 rounded-lg">
                <ul className="menu menu-horizontal w-full">
                    {links.map(([label, url]) => (
                        <li key={url} className="">
                            <Link
                                className={cn({
                                    'active': url === location.pathname,
                                })}
                                to={url}>
                                {label}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
            
            <div className="mt-4">
                <Outlet />
            </div>
        
        </div>
        
    )
    
}

export default Tracking
