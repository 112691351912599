import { useState } from 'react'

const DatabaseManagerViewModel = () => {
    
    const [tab, setTab] = useState('/dashboard/database')
    
    return {
        
        // State
        tab,
        setTab,
        
    }
    
}

export default DatabaseManagerViewModel
