import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { MdOutlineAddBox } from 'react-icons/md'
import { useWireValue } from '@forminator/react-wire'
import cn from 'classnames'
import * as store from '$store'

import { ST_VAL_EXT_KEY, SYSTEM_TYPES } from '@/constants/exampleManager'
import { Selectbox } from '@/components/shared/Selectbox'

const ExampleManagerTabs = ({ setIncludeTemplateModal, children }) => {
    
    const [searchParams, setSearchParams] = useSearchParams({ system: ST_VAL_EXT_KEY })
    const systemTemplates = useWireValue(store.systemTemplates)
    
    // Store search params in variables first
    const systemParam = searchParams.get('system')
    const templateIdParam = searchParams.get('templateId')
    
    const system = useMemo(() => systemParam || ST_VAL_EXT_KEY, [systemParam])
    const templateId = useMemo(() => templateIdParam, [templateIdParam])
    
    const currentTemplates = useMemo(() =>
        systemTemplates.filter(st => st.system === system).sort((a, b) => a.name.localeCompare(b.name)),
    [systemTemplates, system])
    
    const selectedTemplate = useMemo(() =>
        currentTemplates.find(ct => ct.id === templateId)
    , [currentTemplates, templateId])
    
    const templateOption = selectedTemplate ? {
        value: selectedTemplate.id,
        label: selectedTemplate.name,
    } : null
    
    useEffect(() => {
        
        if (!currentTemplates?.[0]) return
        
        setSearchParams(prev => {
            // if its already a valid template, then i dont need to reset it
            if (currentTemplates.some(ct => ct.id === prev.get('templateId')))
                return prev
            
            prev.set('templateId', currentTemplates[0].id)
            return prev
        })
        
    }, [system, currentTemplates])
    
    return (
        <div className="flex flex-col">
            <div role="tablist" className="justify-start mt-2 shadow-md tabs tabs-boxed w-fit">
                {SYSTEM_TYPES.map(tab =>
                    <a
                        key={tab.key}
                        role="tab"
                        className={cn('tab whitespace-nowrap', { 'tab-active': system === tab.key })}
                        onClick={() => {
                            if (tab.key === system) return
                            
                            setSearchParams(prev => {
                                prev.set('system', tab.key)
                                prev.delete('templateId')
                                return prev
                            })
                        }}>
                        {tab.value}
                    </a>,
                )}
            </div>
            <div className="flex flex-row gap-2 mt-4">
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col ">
                        <label htmlFor="template"> Template</label>
                        <div className="flex">
                            {setIncludeTemplateModal && 
                                <a className="flex items-center px-2 cursor-pointer
                                    btn btn-secondary join-item tooltip tooltip-bottom-right"
                                data-tip="Add a new Template"
                                onClick={() => setIncludeTemplateModal(true)}>
                                    <MdOutlineAddBox className="text-xl" />
                                </a>}
                            
                            <Selectbox
                                className="w-[200px] react-tiny-popover-container" 
                                handleChange={(ev, value) => {
                                    setSearchParams(prev => { {
                                        prev.set('templateId', value.value)
                                        return prev
                                    } })
                                }}
                                name="templates"
                                option={templateOption}
                                options={currentTemplates.map(tmpl => ({
                                    key: tmpl.id,
                                    value: tmpl.id,
                                    label: tmpl.name,
                                }))}/>
                            <div className="ml-4">
                                
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExampleManagerTabs
