import { memo } from 'react'

const DatabaseSyncModelSearch = ({
    query,
    onChange,
}) => {
    
    return (
        
        <div className="flex items-center content-center gap-2">
            
            <input
                className="w-full"
                type="text"
                autoComplete="off"
                value={query}
                placeholder="Filter models..."
                onKeyUp={e => e.key === 'Escape' && onChange('')}
                onChange={e => onChange(e.target.value)} />
        
        </div>
        
    )
    
}

export default memo(DatabaseSyncModelSearch)
