import Logger from '@utils/log'
import { useEffect, useCallback } from 'react'
import { useWire, useWireState, useWireValue, useSubscribe } from '@forminator/react-wire'
import * as store from '@store'
import * as actions from '@actions'
import { fetchApiConfigInterval, fetchApiConfigTimeout, storeInitialValues } from '@constants/shared'
import { postEvent } from '@utils/eventBus'
import { EVENT_ORG_CHANGED } from '@constants/events'
import useScheduledPoll from '@hook/useScheduledPoll'
import { toast } from 'react-toastify'
import { useRealtimeUpdateCredits } from './useRealtimeUpdateCredits'

const log = new Logger('hook/useInit')

const useInit = () => {
    
    const isFetchingUser = useWireValue(store.isFetchingUser)
    
    /** @type User */
    const user = useWireValue(store.user)
    const [projects, setProjects] = useWireState(store.projects)
    const [currentProject, setCurrentProject] = useWireState(store.currentProject)
    const [currentOrganization, setCurrentOrganization] = useWireState(store.currentOrganization)
    const collections = useWire(store.collections)
    const runsheets = useWire(store.runsheets)
    const documents = useWire(store.documents)
    const currentCollection = useWire(store.currentCollection)
    const currentRunsheet = useWire(store.currentRunsheet)
    
    useEffect(() => {
        
        if (!user) return
        
        // Initial request for settings & feature flags
        actions.fetchParseConfig().catch(console.warn)
        actions.updateLocalCredits().catch(console.warn)
        
        // Fetch settings & feature flags
        const t = setInterval(() => {
            Promise.race([
                actions.fetchParseConfig,
                new Promise(resolve => setTimeout(resolve, fetchApiConfigTimeout)),
            ]).catch(console.warn)
        }, fetchApiConfigInterval)
        
        return () => clearInterval(t)
        
    }, [user])
    
    useEffect(() => {
        
        // Don't validate the current org until we've finished fetching the user,
        // otherwise we might incorrectly reset a valid org
        if (isFetchingUser) return
        
        const hasOrgs = user?.organizations?.length > 0
        const currentOrgIsValid = (
            hasOrgs &&
            currentOrganization &&
            user.organizations.some(it => it.id === currentOrganization.id)
        )
        
        // If the user has an outdated current org, reset it to avoid confusion
        if (hasOrgs && !currentOrgIsValid)
            setCurrentOrganization(null)
        
        // Handled by the `apiConfig` effect
        if (!hasOrgs) return
        
        // Make sure a current org is set, and that it still exists
        // in the user's list, as it may have changed or been deleted
        if (!currentOrganization || !user.organizations.some(it => it.id === currentOrganization.id))
            setCurrentOrganization(user.organizations.sort((a, b) => a.createdAt - b.createdAt)?.[0])
        
        //
        
        // @todo don't delete, save for next branch
        /* if (!projects?.length) {
            navigate.toProjects()
            return
        }
        
        if (!currentProject) {
            setCurrentProject(projects
                .sort((a, b) => a.createdAt + b.createdAt)
                .shift())
            return
        } */
        
    }, [
        isFetchingUser,
        user,
        projects,
        currentProject,
        currentOrganization,
    ])
    
    const updateUserAndOrgCredits = useCallback(async () => {
        
        try {
            const currCredits = store.credits.getValue()
            
            if (!currCredits) return
            
            const nextCredits = await actions.updateLocalCredits()
            
            if (nextCredits.organization !== currCredits.organization)
                toast.success(`Organization credits updated: $${nextCredits.organization}`)
            
        } catch (e) {
            
            console.error('useInit#useScheduledPoll', e)
            
        }
        
    }, [])
    
    useRealtimeUpdateCredits()
    useScheduledPoll(store.lastCreditsPollTime, 1, updateUserAndOrgCredits)
    
    useSubscribe(store.currentOrganization, useCallback(async value => {
        // Initial page load won't have an org set yet
        if (!value) return
        
        console.info('Organization changed:', value?.name)
        
        // Reset main local data buckets to their initial values
        setProjects(storeInitialValues.projects)
        collections.setValue(storeInitialValues.collections)
        runsheets.setValue(storeInitialValues.runsheets)
        documents.setValue(storeInitialValues.documents)
        currentRunsheet.setValue(null)
        currentCollection.setValue(null)
        setCurrentProject(null)
        
        // Fetch settings, feature flags & escrow notifications again
        try {
            await actions.fetchParseConfig()
            await actions.updateLocalCredits()
        } catch (e) {
            log.e('Failed to fetch app init', e)
        }
        
        // Reload the window to fetch new data
        // @todo would be nice to have a way to signal this in-app instead of a full reload
        // window.location.reload()
        postEvent(EVENT_ORG_CHANGED, value)
        
    }, []))
    
}

export default useInit
