import { Menu } from '@headlessui/react'
import cn from 'classnames'

import { FaCheckCircle } from 'react-icons/fa'

import './Dropdown.css'
import { noop } from '@utils'

const defaultProps = {
    className: '',
    menuButtonClassName: '',
    menuClassName: '',
    menuItemClassName: '',
    style: {},
    label: null,
    items: [],
    idKey: 'id',
    labelKey: 'label',
    valueKey: 'value',
    activeKey: 'active',
    onChange: noop,
    withCheck: true,
    renderItem: null,
}

const Dropdown = props => {
    
    const mergedProps = { ...defaultProps, ...props }
    
    const {
        className,
        menuButtonClassName,
        menuClassName,
        menuItemClassName,
        style,
        label,
        items,
        idKey,
        labelKey,
        valueKey,
        activeKey,
        onChange,
        withCheck,
        renderItem,
    } = mergedProps
    
    return (
        
        <Menu className={cn('Dropdown', className)} style={style} as="div">
            
            <Menu.Button className={menuButtonClassName}>
                {label}
            </Menu.Button>
            
            <Menu.Items className={menuClassName}>
                
                <div className="menu-inner">
                    
                    {items.map(it => (
                        <Menu.Item
                            key={it[idKey]}
                            className={cn(menuItemClassName, it.className, {
                                'active': it[activeKey],
                            })}>
                            <div onClick={() => onChange(it[valueKey] || it)}>
                                {renderItem ? renderItem(it, mergedProps) : (
                                    <div className="menu-item-inner">
                                        {it.Icon && <it.Icon />}
                                        <div className="menu-item-label">
                                            {it[labelKey]}
                                        </div>
                                        {withCheck && (
                                            <div className="menu-item-icon">
                                                <FaCheckCircle className={cn('text-lg', {
                                                    'opacity-40': it[activeKey],
                                                    'opacity-0': !it[activeKey],
                                                })} />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </Menu.Item>
                    ))}
                
                </div>
            
            </Menu.Items>
        
        </Menu>
        
    )
    
}

export default Dropdown
