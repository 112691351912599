import { useState, useMemo } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import * as actions from '$actions'
import useCheckableItems from '@hook/useCheckableItems'
import { toast } from 'react-toastify'

/** @type {SyncDatabaseModelsOptions} */
const initialSelectedOptions = {
    alter: true,
}

const DatabaseSyncViewModel = () => {
    
    const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions)
    const [searchQuery, setSearchQuery] = useState('')
    
    const {
        data: modelNames,
        error: modelNamesError,
        isLoading: modelNamesIsLoading,
    } = useQuery({
        queryKey: ['modelNames'],
        queryFn: actions.listDatabaseModels,
        enabled: true,
        retry: 0,
    })
    
    const {
        mutate: syncModels,
        isLoading: syncModelsIsLoading,
        error: syncModelsError,
    } = useMutation({
        queryKey: ['syncModels', selectedOptions],
        mutationFn: () => actions.syncDatabaseModels(selectedModels, selectedOptions),
        onSuccess: () => {
            console.log(`Model ${selectedModels} synced`)
            toast.success(`Model ${selectedModels} synced`)
        },
        onError: e => {
            console.error(`Error syncing model "${selectedModels}"`, e)
            toast.error(`Failed to sync model "${selectedModels}"`)
        },
    })
    
    /*
    checked,
    allChecked,
    someChecked,
    hasChecked,
    toggleChecked,
    toggleAllChecked,
    */
    const checkableModelNames = useCheckableItems(modelNames || [])
    
    const filteredModelNames = useMemo(() => {
        
        if (!searchQuery?.length)
            return modelNames
        
        return modelNames.filter(it => it.toLowerCase()
            .includes(searchQuery.toLowerCase()))
        
    }, [modelNames, searchQuery])
    
    const filteredModelNamesGrouped = useMemo(() => {
        
        if (!filteredModelNames?.length)
            return []
        
        return [
            filteredModelNames.slice(0, Math.ceil(filteredModelNames.length / 2)),
            filteredModelNames.slice(Math.ceil(filteredModelNames.length / 2)),
        ]
        
    }, [filteredModelNames])
    
    const selectedModels = useMemo(() => (
        checkableModelNames.checked
    ), [checkableModelNames])
    
    const canSyncModels = useMemo(() => (
        selectedModels?.length > 0
    ), [selectedModels])
    
    return {
        
        // State
        selectedOptions,
        setSelectedOptions,
        searchQuery,
        setSearchQuery,
        
        // Memos
        filteredModelNames,
        filteredModelNamesGrouped,
        selectedModels,
        canSyncModels,
        
        // React Query
        modelNames,
        modelNamesError,
        modelNamesIsLoading,
        
        syncModels,
        syncModelsIsLoading,
        syncModelsError,
        
        // Checkable Items
        checkableModelNames,
        
    }
    
}

export default DatabaseSyncViewModel
