
/**
 * @typedef {string} Files that are waiting to be processed or uploaded
 */
export const BATCH_UPLOAD_STATE_PENDING = 'pending'

/**
 * @typedef {string} Files that are being processed
 */
export const BATCH_UPLOAD_STATE_PROCESSING = 'processing'

/**
 * @typedef {string} Files that have been processed successfully
 */
export const BATCH_UPLOAD_STATE_PROCESSING_COMPLETE = 'processing_complete'

/**
 * @typedef {string} Files that are being uploaded
 */
export const BATCH_UPLOAD_STATE_UPLOADING = 'uploading'

/**
 * @typedef {string} Files that have been processed and uploaded, and succeeded
 */
export const BATCH_UPLOAD_STATE_COMPLETE = 'complete'

/**
 * @typedef {string} Files that have been processed or uploaded, and failed
 */
export const BATCH_UPLOAD_STATE_FAILED = 'failed'

/**
 * @typedef {string[]} All possible file states
 */
export const BATCH_UPLOAD_STATES = [
    BATCH_UPLOAD_STATE_PENDING,
    BATCH_UPLOAD_STATE_PROCESSING,
    BATCH_UPLOAD_STATE_PROCESSING_COMPLETE,
    BATCH_UPLOAD_STATE_UPLOADING,
    BATCH_UPLOAD_STATE_COMPLETE,
    BATCH_UPLOAD_STATE_FAILED,
]

/**
 * @typedef {string[]} Files that are currently being processed or uploaded
 */
export const BATCH_UPLOAD_STATES_ACTIVE = [
    BATCH_UPLOAD_STATE_PROCESSING,
    BATCH_UPLOAD_STATE_UPLOADING,
]

/**
 * @typedef {string[]} Files that have been processed and uploaded, and succeeded or failed
 */
export const BATCH_UPLOAD_STATES_FINISHED = [
    BATCH_UPLOAD_STATE_COMPLETE,
    BATCH_UPLOAD_STATE_FAILED,
]
