import { useEffect } from 'react'
import useFirebaseViewModel from './FirebaseViewModel'

import CircleSpinner from '@components/shared/CircleSpinner'
import { MdRefresh } from 'react-icons/md'

import './Firebase.css'

const Firebase = () => {
    
    const vm = useFirebaseViewModel()
    
    useEffect(() => { vm.fetchRemoteConfig() }, [])
    
    return (
        
        <div className="Firebase">
            
            <header className="flex items-baseline justify-between mb-3 content-baseline">
                
                <h4 className="!mb-0 text-xl text-dashboard-primary">
                    Firebase
                </h4>
                
                <div className="flex items-center content-center justify-end gap-3">
                    <button
                        className="flex items-center content-center justify-center w-auto text-blue-400 btn btn-info"
                        disabled={vm.loading}
                        onClick={vm.syncRemoteConfig}>
                        <MdRefresh className="text-2xl" />
                        <span className="ml-3">
                            SYNC REMOTE CONFIG
                        </span>
                    </button>
                </div>
            
            </header>
            
            <div className="">
                
                <div className="flex justify-between items-baseline content-baseline gap-4">
                    <div className="flex items-baseline gap-4 mb-4 content-baseline">
                        <p><b>Environment:</b></p>
                        <p>{vm.environment}</p>
                    </div>
                    <div className="flex justify-end items-baseline content-baseline w-full">
                        <input
                            className="w-1/3"
                            type="text"
                            autoComplete="off"
                            value={vm.query}
                            placeholder="Filter feature flags..."
                            onKeyUp={e => e.key === 'Escape' && vm.setQuery('')}
                            onChange={e => vm.setQuery(e.target.value?.toLowerCase() ?? '')} />
                    </div>
                </div>
                
                {vm.error && (
                    <div className="text-red-500">{vm.error}</div>
                )}
                
                {vm.loading && (
                    <CircleSpinner className="absolute-centered" />
                )}
                
                {(!vm.error && !vm.loading) && (
                    vm.remoteConfig ? (
                        <table className="w-full table-auto table-pin-rows FirebaseConfigTable">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(vm.groupedHeaders).map(groupKey => {
                                    
                                    const group = vm.groupedHeaders[groupKey]
                                    
                                    // Loop through all headers in that group & render cols
                                    return group.items.map(header => {
                                        
                                        // Re-prefix the environment (e.g. "develop_") to correctly get keys
                                        const it = vm.remoteConfig[vm.environment + '_' + header]
                                        
                                        return (
                                            <tr key={header} style={{ '--group-color': group.color }}>
                                                <td>{header}</td>
                                                <td>{it.valueType}</td>
                                                <td>{it.defaultValue?.value?.toString()}</td>
                                            </tr>
                                        )
                                        
                                    })
                                    
                                })}
                            </tbody>
                        </table>
                    ) : (
                        <p><i>No config found</i></p>
                    )
                )}
                
                {/* <div className="max-w-screen-lg mx-auto">
                    <pre>
                        <code>
                            {JSON.stringify(vm.remoteConfig, null, 4)}
                        </code>
                    </pre>
                </div> */}
            
            </div>
        
        </div>
        
    )
    
}

export default Firebase
