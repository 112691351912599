import Logger from '@utils/log'
import api from '$lib/api'
import { createQueryString } from '@/utils'

const log = new Logger('actions/states')

export const fetchTrackingReport = async (startDate, endDate) => {
    
    const dateParams = {
        startDate, 
        endDate,
    }
    
    const queryParams = createQueryString(dateParams)
    
    try {
        
        return await api.get(`/admin/tracking-report${queryParams}`, {
            responseType: 'blob',
        })
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

/**
 * Fetches ATR billable events
 * 
 * @param {string} selectedOrg - The ID of the organization to fetch the billable events for
 * @param {string} startDate - The start date of the billable events to fetch
 * @param {string} endDate - The end date of the billable events to fetch
 * @param {string} order - The order of the billable events to fetch
 * @param {string} orderDirection - The order direction of the billable events to fetch (ASC or DESC)
 * @param {number} offset - The offset of the billable events to fetch
 * @param {number} limit - The limit of the billable events to fetch
 * @returns {Promise<object>} - The fetched billable events
 */
export const fetchAtrBillableEvents = async (
    selectedOrg,
    startDate,
    endDate,
    order,
    orderDirection,
    offset,
    limit,
) => {
    
    const dateParams = {
        organizationId: selectedOrg?.id,
        startDate,
        endDate,
        order,
        orderDirection,
        offset,
        limit,
    }
    
    if (dateParams.order)
        dateParams.order = `${dateParams.order},DESC`
    
    const queryParams = createQueryString(dateParams)
    
    try {
        
        const res = await api.get(`/admin/atr/billable-events${queryParams}`)
        
        console.log('fetchAtrBillableEvents', res.data)
        
        return res.data
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}
