import * as store from '@/store'
import { useWireValue } from '@forminator/react-wire'
import { useEffect, useRef } from 'react'

/**
 * Polls a function every X minutes
 * and saves the last poll time in local storage
 * 
 * @param {WireState<Date|null>} lastRunWire - Wire to save the last poll time in
 * @param {number} delayMinutes - Delay in minutes
 * @param {function} callback - Function to poll - needs to be well memoized otherwise this goes crazy
 * @returns {function} Function to stop polling
 */
const useScheduledPoll = (lastRunWire, delayMinutes, callback) => {
    
    const timeoutRef = useRef(null)
    const remoteConfig = useWireValue(store.remoteConfig)
    
    useEffect(() => {
        
        if (remoteConfig?.realtimeEnabled) return
        
        const checkAndRun = async () => {
            
            const lastRun = lastRunWire.getValue() || 0
            const now = Date.now()
            const elapsedMinutes = (now - lastRun) / (1000 * 60)
            
            if (elapsedMinutes >= delayMinutes) {
                await callback()
                lastRunWire.setValue(now)
            }
            
            // Check every minute
            timeoutRef.current = setTimeout(checkAndRun, 60000)
            
        }
        
        checkAndRun()
            .catch(e => console.error('useScheduledPoll', e))
        
        return () => {
            
            if (timeoutRef.current)
                clearTimeout(timeoutRef.current)
            
        }
        
    }, [lastRunWire, delayMinutes, callback])
    
}

export default useScheduledPoll
