import { useState, useMemo, useCallback, useEffect } from 'react'
import * as actions from '$actions'
import randomColor from 'randomcolor'
import { arrayGroupByPrefix, sleep } from '@utils'
import { toast } from 'react-toastify'

const useFirebaseViewModel = () => {
    
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const [remoteConfig, setRemoteConfig] = useState({})
    const [headers, setHeaders] = useState([])
    const [environment, setEnvironment] = useState('unknown')
    const [query, setQuery] = useState('')
    
    const groupedHeaders = useMemo(() => {
        
        if (!headers?.length) return {}
        
        const stripPrefixFn = it => it.substring(it.indexOf('_') + 1)
        const groups = arrayGroupByPrefix(headers.map(stripPrefixFn))
        
        return Object.keys(groups).reduce((acc, groupKey) => {
            
            const items = query?.length > 0
                ? groups[groupKey].filter(it => it.toLowerCase().includes(query))
                : groups[groupKey]
            
            return {
                ...acc,
                [groupKey]: {
                    items,
                    color: randomColor({
                        // hue: 'random',
                        // luminosity: 'bright', // dark
                        // alpha: 0.5,
                    }),
                },
            }
            
        }, {})
        
    }, [headers, query])
    
    const fetchRemoteConfig = useCallback(async () => {
        
        setError()
        setLoading(true)
        
        try {
            setRemoteConfig(await actions.getFirebaseRemoteConfig())
        } catch (e) {
            console.error('fetchRemoteConfig', e)
            setError(e?.message ?? 'Unknown error')
            toast.error('Failed to fetch Firebase Remote Config')
        }
        
        setLoading(false)
        
    }, [])
    
    const syncRemoteConfig = useCallback(async () => {
        
        setError()
        setLoading(true)
        
        try {
            await actions.syncFirebaseRemoteConfig()
            await sleep(3)
            await fetchRemoteConfig()
            toast.success('Scheduled sync of Firebase Remote Config')
        } catch (e) {
            console.error('syncRemoteConfig', e)
            setError(e?.message ?? 'Unknown error')
            toast.error('Failed to sync Firebase Remote Config')
        }
        
        setLoading(false)
        
    }, [fetchRemoteConfig])
    
    useEffect(() => {
        
        if (!remoteConfig) return
        
        try {
            
            const keys = Object.keys(remoteConfig ?? {})
            
            if (!keys.length) return
            
            setHeaders(keys)
            setEnvironment(keys[0].substring(0, keys[0].indexOf('_')))
            
        } catch (e) {
            
            console.error('failed to set headers or environment', e)
            
        }
        
    }, [remoteConfig])
    
    return {
        
        error,
        setError,
        loading,
        setLoading,
        remoteConfig,
        setRemoteConfig,
        headers,
        setHeaders,
        environment,
        setEnvironment,
        query,
        setQuery,
        groupedHeaders,
        
        fetchRemoteConfig,
        syncRemoteConfig,
        
    }
    
}

export default useFirebaseViewModel
