import TrackingReport from './TrackingReport'
import CostAndRevenue from './CostAndRevenue'

const TrackingHome = () => {
    
    return (
        
        <div className="TrackingHome">
            
            <div className="flex gap-4 h-auto">
                <TrackingReport />
                <CostAndRevenue />
            </div>
        
        </div>
        
    )
    
}

export default TrackingHome
