import { sleep } from '@utils/index'

// @todo @deprecated - to be replaced by the worker
const getViewportForPageSplitting = page => {
    
    // Resize to a maximum width of 4096 pixels while maintaining aspect ratio
    const maxWidth = 4096
    const defaultDPI = 72
    const desiredDPI = 300
    
    let viewport = page.getViewport({ scale: desiredDPI / defaultDPI })
    
    if (viewport.width > maxWidth) {
        const scaleFactor = maxWidth / viewport.width
        
        viewport = page.getViewport({ scale: desiredDPI / defaultDPI * scaleFactor })
    }
    
    return viewport
    
}

// @todo @deprecated - to be replaced by the worker
// eslint-disable-next-line no-unused-vars
const splitPdfPageImage = async (onProgress, i, pdfDoc) => {
    
    const startTime = performance.now()
    
    onProgress(i + 1)
    
    const page = await pdfDoc.getPage(i)
    const viewport = getViewportForPageSplitting(page)
    
    // eslint-disable-next-line no-restricted-globals
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    
    canvas.width = viewport.width
    canvas.height = viewport.height
    
    await page.render({ canvasContext: context, viewport }).promise
    
    // Allow UI/progress updates
    await sleep(100)
    
    const endTime = performance.now()
    const totalDurationMillis = endTime - startTime
    
    return {
        uri: canvas.toDataURL('image/png'),
        index: i,
        totalDurationMillis,
    }
    
}

/**
 * Splits a PDF file into individual page images using a web worker
 * 
 * @param {File} file - The PDF file
 * @param {Function} [onProgress] - Callback (progress: number)
 * @param {Function} [onBeforeStart] - Callback (totalPages: number)
 * @param {Function} [onBeforeComplete] - Callback ()
 * @returns {Promise<SplitPdfPageImagesResultPayload>} - An array of image data URIs
 */
export const splitPdfPageImages = (
    file,
    onProgress = null,
    onBeforeStart = null,
    onBeforeComplete = null,
) => new Promise((resolve, reject) => {
    
    try {
        
        const startTime = performance.now()
        
        if (file?.type !== 'application/pdf')
            return reject(new Error('Invalid PDF file'))
        
        const fileReader = new FileReader()
        const pdfWorker = new Worker(new URL('./pdfWorker.js', import.meta.url), { type: 'module' })
        
        fileReader.onload = async () => {
            
            try {
                
                let totalPages = 0
                const arrayBuffer = fileReader.result
                
                pdfWorker.onmessage = event => {
                    
                    const { type, payload } = event.data
                    
                    switch (type) {
                        
                        case 'SPLIT_START':
                            totalPages = payload.totalPages
                            onBeforeStart?.(payload.totalPages)
                            break
                        
                        case 'SPLIT_PROGRESS':
                            onProgress?.(payload.current)
                            break
                        
                        case 'SPLIT_COMPLETE': {
                            
                            const endTime = performance.now()
                            const totalDurationMillis = parseFloat((endTime - startTime).toFixed(2))
                            
                            onBeforeComplete?.()
                            
                            resolve({
                                results: payload,
                                stats: {
                                    numPages: totalPages,
                                    totalDurationMillis,
                                    totalDurationSeconds: totalDurationMillis / 1000,
                                    avgTimePerPageMillis: totalDurationMillis / totalPages,
                                    avgTimePerPageSeconds: (totalDurationMillis / totalPages) / 1000,
                                },
                            })
                            
                            // Clean up worker after completion
                            pdfWorker.terminate()
                            break
                            
                        }
                        
                        case 'SPLIT_ERROR':
                            reject(new Error(payload))
                            pdfWorker.terminate()
                            break
                        
                        default:
                            // console.warn('Unknown message type from worker:', type)
                        
                    }
                    
                }
                
                const canvas = new OffscreenCanvas(600, 800)
                
                // Send array buffer to worker instead of URL
                pdfWorker.postMessage({
                    type: 'SPLIT_PDF',
                    payload: {
                        pdfData: arrayBuffer,
                        fileName: file.name,
                        canvas,
                    },
                }, [arrayBuffer, canvas]) // Transfer the buffer to avoid copying
                
            } catch (e) {
                
                console.error('splitPdfPageImages', e)
                pdfWorker.terminate()
                reject(e)
                
            }
            
        }
        
        fileReader.readAsArrayBuffer(file)
        
    } catch (e) {
        
        reject(e)
        
    }
    
})
