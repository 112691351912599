import { createWire, createSelector } from '@forminator/react-wire'
import { createPersistedWire } from 'react-wire-persisted'
import { keys } from '@constants'
import { randomNumber } from '@/utils'
import {
    BATCH_UPLOAD_STATES_ACTIVE,
    BATCH_UPLOAD_STATES_FINISHED,
    BATCH_UPLOAD_STATE_PENDING,
    BATCH_UPLOAD_STATE_PROCESSING,
    BATCH_UPLOAD_STATE_PROCESSING_COMPLETE,
    BATCH_UPLOAD_STATE_UPLOADING,
    BATCH_UPLOAD_STATE_COMPLETE,
    BATCH_UPLOAD_STATE_FAILED,
} from '@/constants'

//region Utilities

const useSampleUploads = false

export const createSampleUploads = () => Array(18).fill(null).map((_, i) => ({
    file: {
        name: `Testing 0${i + 1}.pdf`,
        size: randomNumber(819200, 2411724),
    },
    max: randomNumber(1, 100),
    value: randomNumber(1, 100),
    state: BATCH_UPLOAD_STATE_PENDING,
}))

/**
 * Filters an array of uploads by state(s)
 * 
 * @param {StagedUploadItem[]} items
 * @param {string|string[]} state
 * @returns {*}
 */
export const filterUploadsByState = (items, state) => items
    .filter(it => Array.isArray(state)
        ? state.includes(it.state)
        : it.state === state,
    ).flat()

//endregion Utilities

// If the upload notification panel is expended
export const globalUploadsPanelExpanded = createPersistedWire(keys.globalUploadsPanelExpanded, true)

export const skipUploadHelperInfoDialog = createPersistedWire(keys.skipUploadHelperInfoDialog, false)

// Options for the upload dialog that applied to all staged files
export const stagedUploadOptions = createWire({})

/**
 * @type {Wire<StagedUploadOptions>}
 * Raw files selected via the browser file input
 */
export const stagedUploadFiles = createWire(useSampleUploads ? createSampleUploads() : [])

export const hasStagedUploadFiles = createSelector({
    get: ({ get }) => get(stagedUploadFiles).length > 0,
})

/**
 * @type StateWire<StagedUploadItem[]>
 * Files that are being processed or uploaded
 */
export const pendingUploads = createWire([])

/**
 * @type StateWire<boolean>
 * If any files are currently being processed or uploaded
 */
export const hasPendingUploads = createSelector({
    get: ({ get }) => get(pendingUploads).length > 0,
})

/**
 * @type StateWire<StagedUploadItem[]>
 * Files that are waiting to be processed or uploaded
 */
export const pendingUploadsPending = createSelector({
    get: ({ get }) => filterUploadsByState(get(pendingUploads), BATCH_UPLOAD_STATE_PENDING),
})

/**
 * @type StateWire<StagedUploadItem[]>
 * Files that are being processed
 */
export const pendingUploadsProcessing = createSelector({
    get: ({ get }) => filterUploadsByState(get(pendingUploads), BATCH_UPLOAD_STATE_PROCESSING),
})

/**
 * @type StateWire<StagedUploadItem[]>
 * Files that are being processed
 */
export const pendingUploadsProcessingComplete = createSelector({
    get: ({ get }) => filterUploadsByState(get(pendingUploads), BATCH_UPLOAD_STATE_PROCESSING_COMPLETE),
})

/**
 * @type StateWire<StagedUploadItem[]>
 * Files that are being uploaded
 */
export const pendingUploadsUploading = createSelector({
    get: ({ get }) => filterUploadsByState(get(pendingUploads), BATCH_UPLOAD_STATE_UPLOADING),
})

/**
 * @type StateWire<StagedUploadItem[]>
 * Files that have been processed and uploaded, and succeeded
 */
export const pendingUploadsComplete = createSelector({
    get: ({ get }) => filterUploadsByState(get(pendingUploads), BATCH_UPLOAD_STATE_COMPLETE),
})

/**
 * @type StateWire<StagedUploadItem[]>
 * Files that have been processed or uploaded, and failed
 */
export const pendingUploadsFailed = createSelector({
    get: ({ get }) => filterUploadsByState(get(pendingUploads), BATCH_UPLOAD_STATE_FAILED),
})

/**
 * @type StateWire<StagedUploadItem[]>
 * Files that are currently being processed or uploaded
 */
export const pendingUploadsActive = createSelector({
    get: ({ get }) => filterUploadsByState(get(pendingUploads), BATCH_UPLOAD_STATES_ACTIVE),
})

/**
 * @type StateWire<StagedUploadItem[]>
 * Files that have been processed and uploaded, and succeeded or failed
 */
export const pendingUploadsFinished = createSelector({
    get: ({ get }) => filterUploadsByState(get(pendingUploads), BATCH_UPLOAD_STATES_FINISHED),
})

/**
 * @type StateWire<StagedUploadItem[]>
 * Files sorted by state, active -> pending -> finished
 */
export const pendingUploadsSortedByState = createSelector({
    get: ({ get }) => [
        ...get(pendingUploadsActive),
        ...get(pendingUploadsPending),
        ...get(pendingUploadsFinished),
    ],
})

export const pendingUploadsQueued = createSelector({
    get: ({ get }) => [
        ...get(pendingUploadsPending),
        ...get(pendingUploadsProcessingComplete),
    ],
})

/**
 * @type StateWire<number>
 * Total progress percentage of all files in the queue
 */
export const pendingUploadsTotalProgress = createSelector({
    get: ({ get }) => {
        
        const finished = get(pendingUploadsFinished).length
        const total = get(stagedUploadFiles).length
        
        return Math.round((finished / total) * 100)
        
    },
})
