import useATRUsageViewModel from './ATRUsageViewModel'
import { snippet } from '@utils'
import CopyToClipboardButton from '$components/CopyToClipboardButton'
import ATRUsageToolbar from '$routes/Tracking/ATRUsage/ATRUsageToolbar'

const ATRUsage = () => {
    
    const vm = useATRUsageViewModel()
    
    return (
        
        <div className="ATRUsage w-full">
            
            <ATRUsageToolbar vm={vm} />
            
            <div className="mt-4">
                
                {!vm.billableEvents?.rows?.length && (
                    <div className="alert alert-info">
                        No billable events found.
                    </div>
                )}
                
                <table className="table table-auto table-zebra w-full">
                    <thead>
                        <tr>
                            <th>Transaction ID</th>
                            <th>Organization ID</th>
                            <th>Organization Name</th>
                            <th>Processing Type</th>
                            <th>Price per Page</th>
                            <th>Total Pages</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vm.billableEvents?.rows?.map((it, index) => (
                            <tr key={index}>
                                <td title={it.transactionId}>
                                    <div className="flex items-center content-center gap-3">
                                        <span>{snippet(it?.transactionId ?? '')}</span>
                                        <CopyToClipboardButton value={it.transactionId} />
                                    </div>
                                </td>
                                <td title={it.organizationId}>
                                    <div className="flex items-center content-center gap-3">
                                        <span>{snippet(it?.organizationId ?? '')}</span>
                                        <CopyToClipboardButton value={it.organizationId} />
                                    </div>
                                </td>
                                <td>
                                    {it.organization?.name ?? '-'}
                                </td>
                                <td>
                                    {it.processingType}
                                </td>
                                <td>
                                    ${it.pricePerPage}
                                </td>
                                <td>
                                    {it.totalPages}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            
            </div>
        
        </div>
        
    )
}

export default ATRUsage
