import { isLikelyADate, isLikelyAGuid, snippet } from '@utils'
import { formatDate } from '@utils/date'

const DatabaseBrowserRow = ({
    row,
    col,
}) => {
    
    const wrap = content => (
        <td key={`${col.columnID}-${row.id}`}>
            {content}
        </td>
    )
    
    const value = row[col.name]
    
    if (isLikelyAGuid(value)) return wrap(
        <code
            className="text-xs break-words min-w-fit"
            title={value}>
            {snippet(value)}
        </code>)
    
    if (isLikelyADate(value)) return wrap(
        <code
            className="text-xs break-words min-w-fit"
            title={value}>
            {formatDate(value, 'MMM DD, YYYY')}
        </code>)
    
    return wrap(value)
    
}

export default DatabaseBrowserRow
