import cn from 'classnames'
import Checkbox from '$components/Forms/Checkbox'

const DatabaseSyncModelsTable = ({
    className,
    modelNames,
    allChecked,
    someChecked,
    hasChecked,
    toggleChecked,
    toggleAllChecked,
}) => {
    
    return (
        
        <table className={cn('DatabaseSyncModelsTable table table-auto', className)}>
            <thead>
                <tr>
                    <th className="w-8">
                        <Checkbox
                            checked={allChecked}
                            indeterminate={someChecked}
                            onChange={toggleAllChecked} />
                    </th>
                    <th>Name</th>
                </tr>
            </thead>
            <tbody>
                {modelNames?.map(it => (
                    <tr key={it}>
                        <td>
                            <Checkbox
                                className=""
                                disabled={allChecked}
                                checked={hasChecked(it)}
                                onChange={() => toggleChecked(it)} />
                        </td>
                        <td>{it}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        
    )
    
}

export default DatabaseSyncModelsTable
