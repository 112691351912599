import useDatabaseSyncViewModel from './DatabaseSyncViewModel'
import { MdSync } from 'react-icons/md'
import DatabaseSyncModelsTable from '$routes/DatabaseManager/widgets/DatabaseSync/DatabaseSyncModelsTable'
import DatabaseSyncModelSearch from '$routes/DatabaseManager/widgets/DatabaseSync/DatabaseSyncModelSearch'

const DatabaseSync = () => {
    
    const vm = useDatabaseSyncViewModel()
    
    //syncDatabaseModel
    
    return (
        
        <div className="DatabaseSync">
            
            <header className="w-full flex items-baseline justify-between content-baseline">
                
                <div className="flex justify-between items-baseline content-baseline gap-4 w-full">
                    <h5 className="text-xl text-dashboard-primary">
                        Database Sync
                    </h5>
                </div>
            
            </header>
            
            <div className="flex flex-col gap-4">
                
                {vm.modelNamesError && (<>
                    <p>Failed to fetch model names.</p>
                    <p>Error: {JSON.stringify(vm.modelNamesError)}</p>
                </>)}
                
                {vm.modelNamesIsLoading && (
                    <div className="flex justify-center p-4 text-center">
                        <div className="loading loading-spinner" />
                    </div>
                )}
                
                {!vm.modelNamesError && !vm.modelNamesIsLoading && (
                    <div className="flex gap-4">
                        
                        <div className="flex flex-col gap-4 w-auto">
                            <DatabaseSyncModelSearch
                                query={vm.searchQuery}
                                onChange={vm.setSearchQuery} />
                            <div className="flex gap-4 w-auto">
                                {vm.filteredModelNamesGrouped.map((filteredModelNames, i) => (
                                    <DatabaseSyncModelsTable
                                        key={`filteredModelNames-group-${i}`}
                                        modelNames={filteredModelNames}
                                        {...vm.checkableModelNames} />
                                ))}
                            </div>
                        </div>
                        
                        <div className="flex flex-col gap-4 w-auto">
                            <div className="flex items-baseline content-baseline gap-4">
                                <h5 className="text-lg">
                                    Selected Models
                                </h5>
                                <button
                                    className="btn btn-warning"
                                    disabled={!vm.canSyncModels || vm.syncModelsIsLoading}
                                    onClick={vm.syncModels}>
                                    {vm.syncModelsIsLoading
                                        ? <span className="loading loading-spinner" />
                                        : <MdSync className="text-2xl"/>}
                                    Sync Database
                                </button>
                            </div>
                            <ul>
                                {vm.selectedModels.map(it => (
                                    <li key={it}>{it}</li>
                                ))}
                            </ul>
                        </div>
                    
                    </div>
                )}
            
            </div>
        
        </div>
        
    )
    
}

export default DatabaseSync
