import { scan } from 'react-scan' // must be imported before React and React DOM
import './utils/logrocket'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { NS } from '@constants'
import { setNamespace } from 'react-wire-persisted'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import Main from '@/app'
import config from './config'
import { BrowserRouter } from 'react-router-dom'
import '@assets/styles/tailwind.css'
/* import '@assets/styles/themes.g.css' */
import '@assets/styles/index.css'
import 'react-toastify/dist/ReactToastify.css'

// Turn this on to see which components are slowing FPS or over-rendering
scan({ enabled: false })

setNamespace(NS)

dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(relativeTime)

const useStrictMode = false

// eslint-disable-next-line no-restricted-globals
const container = document.getElementById('root')
const root = createRoot(container)

const renderRoot = element => root.render(useStrictMode ? (
    <StrictMode>
        {element}
    </StrictMode>
) : (
    element
))

if (config.enableDataRouter)
    renderRoot(<Main />)
else
    renderRoot(
        <BrowserRouter>
            <Main />
        </BrowserRouter>)
