import { useState, useEffect, useMemo, useCallback } from 'react'
import { useWireState, useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'
import * as appActions from '@actions'
import cn from 'classnames'

import Modal from '$components/Modal'
import {
    FaPlus,
    FaMinus,
} from 'react-icons/fa'

const ManageUserCreditsModal = ({
    className,
    onUserUpdated,
    ...props
}) => {
    
    const [credits, setCredits] = useState(0.00)
    
    const [open, setOpen] = useWireState(store.modalManageUserCreditsOpen)
    const [user, setUser] = useWireState(store.modalManageUserCreditsUser)
    const remoteConfig = useWireValue(store.remoteConfig)
    
    const fullName = useMemo(() => `${user?.firstName} ${user?.lastName}`, [user])
    
    const onUpdateCreditsClick = useCallback(async () => {
        
        try {
            
            const res = await actions.updateUserCredits(user.id, {
                credits,
            })
            
            if (!remoteConfig?.realtimeEnabled) await appActions.updateLocalCredits()
            
            onUserUpdated(res.data)
            
        } catch (e) {
            
            // @todo show error
            console.error('onUpdateCreditsClick', e)
            
        }
        
    }, [user, credits])
    
    useEffect(() => {
        
        if (!user) return
        
        setCredits(user.credits ?? 0)
        
    }, [user])
    
    if (!user) return null
    
    return (
        
        <Modal
            className={className}
            open={open}
            setOpen={value => {
                if (!value) setUser(null)
                setOpen(value)
            }}
            title="Edit User Credits"
            actionLabel="UPDATE CREDITS"
            actionOnClick={onUpdateCreditsClick}
            {...props}>
            
            <div className="flex flex-col justify-center text-center">
                
                <div className="">
                    {fullName?.length > 0 && (
                        <div className="text-xl">{fullName}</div>
                    )}
                    <div className="text-base">{user?.email}</div>
                    {user?.organizations?.length > 0 && (
                        <div className="text-base">
                            <i>Member of:</i>
                            {' '}
                            {user?.organizations?.map(it => it.name).join(', ')}
                        </div>
                    )}
                    <div className="mt-4 text-base">
                        Current balance: ${(user?.credits ?? 0).toFixed(2)}
                    </div>
                </div>
                
                <div className="flex items-center content-center justify-between mx-5 mt-10">
                    
                    <button
                        className="text-3xl text-warning"
                        onClick={() => setCredits(Math.max(credits - 50, 0))}>
                        <FaMinus className="inline text-base" /> 50
                    </button>
                    
                    <div className="p-3 mx-auto text-center rounded w-min ring-2">
                        <div className="">Balance</div>
                        <input
                            className={cn(
                                'w-48 p-1 m-0 text-5xl text-center bg-transparent',
                                'border-none rounded outline-none ring-0',
                                'focus:border-none focus:outline-none',
                            )}
                            type="number"
                            step={1.0}
                            value={credits}
                            onChange={e => setCredits(parseInt(e.target.value, 10))} />
                    </div>
                    
                    <button
                        className="text-3xl text-success"
                        onClick={() => setCredits(credits + 50)}>
                        <FaPlus className="inline text-base" /> 50
                    </button>
                
                </div>
            
            </div>
        
        </Modal>
        
    )
    
}

export default ManageUserCreditsModal
