import { useState, useEffect } from 'react'
import { useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import buildInfo from '@/build.json'
import dayjs from 'dayjs'
import cn from 'classnames'

import SidebarPrimaryNav from '$components/Sidebar/SidebarPrimaryNav'
import SidebarQuickSettings from '$components/Sidebar/SidebarQuickSettings'
import {
    MdKeyboardDoubleArrowLeft,
    MdKeyboardDoubleArrowRight,
    MdSettings,
} from 'react-icons/md'

import './Sidebar.css'

const SIDEBAR_STORAGE_KEY = 'sidebar-collapsed'

const Sidebar = ({ collapsed, setCollapsed }) => {
    const [quickSettingsOpen, setQuickSettingsOpen] = useState(false)
    const user = useWireValue(store.user)
    
    useEffect(() => {
        const savedState = localStorage.getItem(SIDEBAR_STORAGE_KEY)
        
        if (savedState !== null) {
            setCollapsed(savedState === 'true')
        } else {
            setCollapsed(window.innerWidth < 768)
        }
    }, [setCollapsed])
    
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setCollapsed(true)
            } else {
                const savedState = localStorage.getItem(SIDEBAR_STORAGE_KEY)
                
                setCollapsed(savedState === 'true')
            }
        }
        
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [setCollapsed])
    
    const toggleCollapsed = () => {
        const newState = !collapsed
        
        setCollapsed(newState)
        localStorage.setItem(SIDEBAR_STORAGE_KEY, newState.toString())
    }
    
    const toggleQuickSettings = () => setQuickSettingsOpen(prev => !prev)
    
    return (
        <aside className={cn('Sidebar', { collapsed })}>
            <div className="content">
                {quickSettingsOpen
                    ? <SidebarQuickSettings toggle={toggleQuickSettings} />
                    : <SidebarPrimaryNav collapsed={collapsed} />}
                
                <div className="h-full grow" />
                
                <footer className="account-info">
                    {!collapsed && <div className="text-sm">{user?.email}</div>}
                    <button className="btn btn-ghost" onClick={toggleQuickSettings}>
                        <MdSettings className="text-lg" />
                    </button>
                </footer>
                
                {!collapsed && (
                    <footer className="build-info">
                        <div>Build {dayjs(buildInfo?.date)
                            ?.format('MMM DD, YYYY [at] hh:mm A') ?? 'UNKNOWN'}
                        </div>
                        <div>{buildInfo?.phrase?.toUpperCase() ?? '-'}</div>
                    </footer>
                )}
            </div>
            
            <div className="collapse-button">
                <button onClick={toggleCollapsed}>
                    {collapsed
                        ? <MdKeyboardDoubleArrowRight className="text-2xl opacity-60" />
                        : <MdKeyboardDoubleArrowLeft className="text-2xl opacity-60" />}
                </button>
            </div>
        </aside>
    )
}

export default Sidebar