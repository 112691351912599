import * as rwp from 'react-wire-persisted'

export const NS = 'parse'

const { key, getPrefixedKeys } = rwp.utils

// Debug: only admins in development, save credentials for easy access
key('subtleCryptoKey')
key('successfulLogins')

// Current config from the API
key('apiConfig')

// System-wide, public Firebase Remote Config settings
key('remoteConfig')

// UI theme
key('theme')

// Authenticated user
key('user')

// Compatibility error warnings
key('ignoreCompatErrors')

// Save last known URL when redirecting to sign-in screen
key('afterSignInRedirectUrl')

// User and org credits
key('credits')

// Last time we polled for credits, so it doesn't trigger on every route change
key('lastCreditsPollTime')

// Global project/org
key('currentProject')
key('currentOrganization')

// En/disable wider annotations document view
key('showExpandedAnnotationView')

// Remember last selected export template
key('exportRunsheetSelectedTemplate')

// Remember the order of the headers selected by the user
key('runsheetHeaders')

// Save the order of the headers selected by the user
key('runsheetOrder')

// Option to "don't remind me" for payment warning dialog
// 24.08.14 disabled for initial tests 
// until defining whether to charge by default from user or org
key('paymentRequiredDialogShouldWarn_disabled') 

// Option to show all labels in LabelStudio
key('labelStudioShowAllLabelsChecked')

// (Admin) Option to show private labels in LabelStudio
key('adminLabelStudioShowPrivateLabelsChecked')

// Option to not show the process new document message
key('processDocument')

// Option to collapse or expand the pending uploads panel
key('globalUploadsPanelExpanded')

// Option to skip the upload helper info dialog
key('skipUploadHelperInfoDialog')

//
key('numberDisplayType')

const prefixedKeys = getPrefixedKeys(NS)

export { prefixedKeys as keys }
