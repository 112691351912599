import ModalBody from '@components/shared/CustomModal/ModalBody'
import ModalHeader from '@components/shared/CustomModal/ModalHeader'
import { useState } from 'react'
import Flatpickr from 'react-flatpickr'
import dayjs from 'dayjs'
import * as actions from '@actions'
import { MdFileDownload } from 'react-icons/md'
import { createSyntheticDownload } from '@utils'
import { toast } from 'react-toastify'

const CostAndRevenue = () => {
    const date = dayjs()
    const today = date.format('YYYY-MM-DD')
    const firstDayOfTheMOnth = date.startOf('month').format('YYYY-MM-DD')
    const [initialDate, setInitialDate] = useState(firstDayOfTheMOnth)
    const [finalDate, setFinalDate] = useState(today)
    const [exportLoading, setExportLoading] = useState(false)
    
    
    const submitForm = async event => {
        event.preventDefault()
        
        setExportLoading(true)
        
        const res = await actions.fetchCostAndRevenue(initialDate, finalDate)
        
        if (res)
            createSyntheticDownload(res.data, res.headers['content-type'], res.headers['content-name'])
        else
            toast.error('Failed to export Title Chain List, please try again.')
        
        setExportLoading(false)
        
    }
    
    return (
        
        
        <div 
            className="
                flex flex-col 
                bg-base-200 
                p-6 gap-4 rounded-lg mx-auto">
            <ModalHeader>
          Export Costs and Revenue
            </ModalHeader>
            <ModalBody>
                <form onSubmit={submitForm} className="flex flex-col p-4 gap-4">
                    <div>
                        
                        <label for="startDate">From Date:</label>
                        <Flatpickr
                            className="input input-bordered w-full"
                            value={initialDate}
                            name="startDate"
                            placeholder="Select date"
                            options={{
                                dateFormat: 'Y-m-d',
                                allowInput: true,
                            }}
                            onChange={fileDateFrom => {
                                setInitialDate(fileDateFrom)
                            }}/>
                    </div>
                    
                    <div>
                        
                        <label for="endDate">To Date:</label>
                        <Flatpickr
                            className="input input-bordered w-full"
                            value={finalDate}
                            name="endDate"
                            placeholder="Select date"
                            options={{
                                dateFormat: 'Y-m-d',
                                allowInput: true,
                            }}
                            onChange={fileDateFrom => {
                                setFinalDate(fileDateFrom)
                            }}/>
                    </div>
                    
                    <button 
                        type="submit" 
                        className="btn btn-primary"
                        disabled={exportLoading}><MdFileDownload />Export</button>
                </form>
            </ModalBody>
        
        </div>
        
        
        
    )
    
}

export default CostAndRevenue
