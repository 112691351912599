import { useState, useMemo, useEffect, useCallback } from 'react'
import { useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'
import useOrgsSearch from '@hook/useOrgsSearch'
import useRepeatableViewModel from '@components/shared/Repeatable/RepeatableViewModel'
import { toast } from 'react-toastify'
import { omit } from '@utils'

const initialServiceApiKey = {
    name: '',
    organizationId: null,
}

const ORG_NONE = { id: null, name: 'None' }

const useRepeatableVm = items => {
    
    const vm = useRepeatableViewModel(1)
    
    useEffect(() => {
        
        if (!items?.length) return
        
        vm.setItems(
            items.map((it, i) => ({
                [`key${i + 1}`]: it,
            })),
        )
        
    }, [items])
    
    return vm
    
}

const useCreateServiceApiKeyModal = ({
    open,
    setOpen,
    existingServiceApiKey,
}) => {
    
    const [loading, setLoading] = useState(false)
    const [selectedOrg, setSelectedOrg] = useState(null)
    const [serviceApiKey, setServiceApiKey] = useState(existingServiceApiKey || initialServiceApiKey)
    
    const isFetchingOrgs = useWireValue(store.isFetchingOrgs)
    
    const orgsSearch = useOrgsSearch({ keepPreviousData: true })
    
    const allowedDomainsRepeatableViewModel = useRepeatableVm(serviceApiKey.allowedDomains)
    const allowedEndpointsRepeatableViewModel = useRepeatableVm(serviceApiKey.allowedEndpoints)
    const resultEmailsRepeatableViewModel = useRepeatableVm(serviceApiKey.resultEmails)
    const resultWebhookUrlsRepeatableViewModel = useRepeatableVm(serviceApiKey.resultWebhookUrls)
    
    const loadingMessage = useMemo(() => {
        
        if (isFetchingOrgs)
            return 'Fetching organizations...'
        
        if (loading)
            return `${existingServiceApiKey?.id ? 'Creating' : 'Updating'} key...`
        
        return ''
        
    }, [isFetchingOrgs, loading])
    
    const modalLabels = useMemo(() => ({
        title: `${existingServiceApiKey?.id ? 'Create' : 'Update'} Service API Key`,
        saveButton: `${existingServiceApiKey?.id ? 'UPDATE' : 'CREATE'} KEY`,
    }), [existingServiceApiKey])
    
    const updateServiceApiKey = (field, isArray = false) => e => {
        
        const payload = {
            ...serviceApiKey,
            [field]: e.target.value ?? '',
        }
        
        if (isArray)
            payload[field] = payload[field].split(',')
        
        setServiceApiKey(payload)
        
    }
    
    const onConfirmServiceApiKeyClick = useCallback(async () => {
        
        setLoading(true)
        
        try {
            
            const getArrayValue = repeatableVm =>
                repeatableVm.items.flatMap(it => Object.values(it))
            
            const payload = {
                ...serviceApiKey,
                organizationId: selectedOrg?.id || undefined,
                allowedDomains: getArrayValue(allowedDomainsRepeatableViewModel),
                allowedEndpoints: getArrayValue(allowedEndpointsRepeatableViewModel),
                resultEmails: getArrayValue(resultEmailsRepeatableViewModel),
                resultWebhookUrls: getArrayValue(resultWebhookUrlsRepeatableViewModel),
            }
            
            if (existingServiceApiKey?.id)
                await actions.updateServiceApiKey(existingServiceApiKey.id, omit(payload, 'id'))
            else
                await actions.createServiceApiKey(payload)
            
            await actions.fetchServiceApiKeys()
            
            toast.success(`${existingServiceApiKey?.id ? 'Updated' : 'Created'} service API key`)
            
            setOpen(false)
            
        } catch (e) {
            
            // @todo show error
            console.error('onConfirmServiceApiKeyClick', e)
            
            toast.error('Failed to create service API key')
            
        }
        
        setLoading(false)
        
    }, [
        serviceApiKey,
        selectedOrg,
        existingServiceApiKey,
        allowedDomainsRepeatableViewModel.items,
        allowedEndpointsRepeatableViewModel.items,
        resultEmailsRepeatableViewModel.items,
        resultWebhookUrlsRepeatableViewModel.items,
    ])
    
    useEffect(() => {
        
        if (!open || !existingServiceApiKey) return
        
        // If the dialog just opened, set the existing service API key, or default
        setSelectedOrg(existingServiceApiKey.organization)
        setServiceApiKey(existingServiceApiKey || initialServiceApiKey)
        
    }, [open, existingServiceApiKey])
    
    useEffect(() => {
        
        if (open) return
        
        // Cleanup
        setSelectedOrg(null)
        setServiceApiKey(initialServiceApiKey)
        
    }, [open])
    
    return {
        
        // Constants
        initialServiceApiKey,
        ORG_NONE,
        
        // State
        loading,
        setLoading,
        selectedOrg,
        setSelectedOrg,
        serviceApiKey,
        setServiceApiKey,
        
        // Global State
        isFetchingOrgs,
        
        // Memos
        loadingMessage,
        modalLabels,
        
        // Hooks
        orgsSearch,
        allowedDomainsRepeatableViewModel,
        allowedEndpointsRepeatableViewModel,
        resultEmailsRepeatableViewModel,
        resultWebhookUrlsRepeatableViewModel,
        
        // Methods
        updateServiceApiKey,
        onConfirmServiceApiKeyClick,
        
    }
    
}

export default useCreateServiceApiKeyModal
