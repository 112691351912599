import { memo } from 'react'
import { MdClose, MdKeyboardArrowUp } from 'react-icons/md'
import cn from 'classnames'

const GUPHeader = ({
    allUploadsComplete,
    stagedUploadFiles,
    globalUploadsPanelExpanded,
    toggleGlobalUploadsPanelExpanded,
    pendingUploadsTotalProgress,
    pendingUploadsFailed,
    resetGlobalUploadsPanel,
}) => {
    
    return (
        
        <header className="flex justify-between items-center content-center gap-2 px-4 py-3">
            
            <p className="font-bold">
                {allUploadsComplete ? 'Uploaded' : 'Uploading'}
                {' '}
                {stagedUploadFiles.length} Documents
            </p>
            
            <div className="flex justify-end items-center content-center gap-2">
                
                {!allUploadsComplete && (
                    <button
                        className="btn btn-sm btn-square btn-ghost"
                        onClick={toggleGlobalUploadsPanelExpanded}>
                        <MdKeyboardArrowUp className={cn('text-2xl text-base-content', {
                            'rotate-180': globalUploadsPanelExpanded,
                        })} />
                    </button>
                )}
                
                {allUploadsComplete && (
                    <button
                        className="btn btn-sm btn-square btn-ghost"
                        onClick={() => resetGlobalUploadsPanel()}>
                        <MdClose className={cn('text-2xl text-base-content')} />
                    </button>
                )}
                
                {!allUploadsComplete && (
                    <div
                        className={cn('radial-progress text-xs transition-all duration-300 ease-in-out', {
                            'text-primary': !allUploadsComplete,
                            'text-error': pendingUploadsFailed.length > 0,
                        })}
                        style={{
                            '--value': pendingUploadsTotalProgress,
                            '--size': '1.9rem',
                            '--thickness': '0.14rem',
                        }}
                        aria-valuenow={pendingUploadsTotalProgress}
                        role="progressbar">
                        {pendingUploadsTotalProgress}%
                    </div>
                )}
            
            </div>
        
        </header>
        
    )
    
}

export default memo(GUPHeader)
