/* eslint-disable no-restricted-globals */
import { useEffect } from 'react'

export const useDaisyUIDropdownFix = () => {
    useEffect(() => {
        const handleClick = event => {
            document.querySelectorAll('.dropdown').forEach(dropdown => {
                const isDropdownButton = event.target.closest('[role="button"]')
                
                if (isDropdownButton && dropdown.contains(isDropdownButton)) {
                    dropdown.classList.toggle('dropdown-open')
                } else {
                    dropdown.classList.remove('dropdown-open')
                }
            })
        }
        
        document.addEventListener('click', handleClick)
        return () => document.removeEventListener('click', handleClick)
    }, [])
}

