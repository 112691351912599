import cn from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import * as actions from '$actions'
import * as store from '$store'
import { ST_VAL_EXT_KEY } from '@/constants/exampleManager'
import { useSearchParams } from 'react-router-dom'
import { useWireValue, useWireState } from '@forminator/react-wire'
import { toast } from 'react-toastify'
import CreatePresetModal from './CreatePresetModal'
import CreateTemplateModal from './CreateTemplateModal'
import ExampleManagerTabs from './ExampleManagerTabs'
import { ST_CHAINER_KEY } from '@/constants/exampleManager'

import { MdAdd } from 'react-icons/md'
import Toggle from '$components/Forms/Toggle'
import { FaTrash } from 'react-icons/fa'
import ConfirmationModal from '@/components/shared/ConfirmationModal'
import ExampleModal from './ExampleModal'
import { Selectbox } from '@/components/shared/Selectbox'
import { DOCUMENT_TYPES } from '@/constants'
import * as $store from '@store'



const DEFAULT_DEFINITION = { key: '', displayName: '', definition: '', visible: true }

const CHAINER_DEFINITION = [
    { key: 'get_document_content', definition: '' },
    { key: 'get_current_ownership', definition: '' },
    { key: 'update_ownership', definition: '' },
]

const DashboardExampleManager = () => {
    
    const [searchParams] = useSearchParams({ system: ST_VAL_EXT_KEY })
    
    const remoteConfig = useWireValue($store.remoteConfig)
    
    const [includeTemplateModal, setIncludeTemplateModal] = useState(false)
    const [showCreatePreset, setShowCreatePreset] = useState(false)
    const [isLoadingFromLiveTable, setIsLoadingFromLiveTable] = useState(false)
    
    const [showExampleManagerModal, setShowExampleManagerModal] = useState(false)
    const [, setSearchParams] = useSearchParams()
    
    // Store the search param value in a variable first
    const systemParam = searchParams.get('system')
    const templateIdParam = searchParams.get('templateId')
    
    const system = useMemo(() => systemParam || ST_VAL_EXT_KEY, [systemParam])
    const templateId = useMemo(() => templateIdParam, [templateIdParam])
    
    const [systemTemplates, setSystemTemplates] = useWireState(store.systemTemplates)
    
    const activeSystemTemplates = useMemo(() => systemTemplates.filter(sys => sys.system === system),
        [systemTemplates, system])
    
    const selectedTemplate = useMemo(() => activeSystemTemplates.find(tmp => tmp.id === templateId) || {},
        [activeSystemTemplates, templateId])
    
    const [shouldDeleteTemplate, setShouldDeleteTemplate] = useState(false)
    const [definitionToDelete, setDefinitionToDelete] = useState(null)
    
    const [templateCollectionsName, setTemplateCollectionsName] = useState([])
    const [templateOrganizationsName, setTemplateOrganizationsName] = useState([])
    
    const [instruction, setInstruction] = useState(selectedTemplate?.instructions || '')
    const [definitions, setDefinitions] = useState([DEFAULT_DEFINITION])
    
    const [chainerConstants, setChainerConstants] = useState(CHAINER_DEFINITION)
    
    const models = useMemo(() => {
        if (!remoteConfig.modelsPerSystem?.length) return []
        
        return remoteConfig.modelsPerSystem.filter(model => model.systems.some(s => s === system))
    }, [remoteConfig.modelsPerSystem, system])
    
    const saveTemplateDocumentType = async documentType => {
        
        await actions.editTemplate(templateId, { documentType })
        
        toast.success('Successfully updated Document Type')
        
    }
    
    const saveModelToVersion = async model => {
        
        await actions.saveModel(templateId, model, 'template')
        
        setSystemTemplates(prev => prev.map(st => st.id === templateId ? { ...st, modelName: model } : st))
        
        toast.success('Successfully updated Model')
        
    }
    
    const loadFromLiveTable = async () => {
        setIsLoadingFromLiveTable(true)
        await actions.loadFromLiveTable(system, templateId)
        setIsLoadingFromLiveTable(false)
        
        toast.success('Successfully loaded Template from LiveTable')
        
    }
    
    const deleteTemplate = async () => {
        
        try {
            
            await actions.deleteTemplate(templateId)
            
            setSearchParams(prev => {
                
                prev.set('templateId', activeSystemTemplates[0].id)
                
                return prev
            })
            
        } catch (err) {
            
            toast.error('Failed to delete template')
            
            console.log(err)
            
        }
        
    }
    
    const createDefinition = async () => {
        
        const defToInsert = definitions.filter(d => d.key)
        
        if (system !== 'chainer') {
            
            await actions.saveInstructionAndDefinitions(
                instruction, selectedTemplate.name, /*baseTemplate*/ null, defToInsert, system, templateId)
            
        } else {
            await actions.saveInstructionAndDefinitions(
                instruction, selectedTemplate.name, null, chainerConstants, system, templateId)
            
        }
        
        toast.success('Successfully updated Instructions and Definitions')
        
    }
    
    const handleDefinitionValue = (key, idx, value) => {
        
        const next = [...definitions]
        
        next[idx] = {
            ...next[idx],
            [key]: value,
        }
        
        setDefinitions(next)
        
    }
    
    const addNewDefinition = () => {
        setDefinitions([...definitions, DEFAULT_DEFINITION])
    }
    
    const deleteDefinition = async definitionId => {
        
        await actions.deleteDefinition(definitionId)
        setDefinitions(definitions.filter(def => def.id !== definitionId))
        
    }
    
    const handleDefinitionChange = (idx, definition, value) => {
        setChainerConstants(prev => {
            
            const next = [...prev]
            
            next[idx] = {
                ...definition,
                definition: value,
            }
            
            return next
            
        })
    }
    
    useEffect(() => {
        
        actions.getExamples()
        actions.getTemplates()
        
    }, [])
    
    useEffect(() => {
        if (!templateId)
            return
        
        actions.getTemplateCollections(templateId).then(res => {
            setTemplateCollectionsName(res)
        })
        
        actions.getTemplateOrganizations(templateId).then(res => {
            setTemplateOrganizationsName(res)
        })
        
    }, [templateId])
    
    useEffect(() => {
        
        if (!selectedTemplate?.id) return
        
        setInstruction(selectedTemplate.instructions || '')
        
        if (system === ST_CHAINER_KEY) {
            
            const chainerDefinitions = CHAINER_DEFINITION.map(cd =>
                selectedTemplate.definitions.find(dd => dd.key === cd.key) || cd)
            
            setChainerConstants(chainerDefinitions)
        } else {
            
            setDefinitions(selectedTemplate.definitions.length ? selectedTemplate.definitions : [DEFAULT_DEFINITION])
        }
        
        
        
    }, [selectedTemplate, system])
    
    
    
    
    return (
        
        <div className="w-full p-4">
            <header>
                <h3>Template Manager</h3>
            </header>
            <div>
                <div className="w-full">
                    
                    <ExampleManagerTabs
                        setIncludeTemplateModal={setIncludeTemplateModal}
                        origin="templateManager">
                        <div className="flex justify-between gap-4 pt-2">
                            <div>
                                <button
                                    className="btn btn-secondary "
                                    onClick={() => setShowExampleManagerModal(true)}>
                                    Edit Examples
                                </button>
                            </div>
                            
                            <div className="flex flex-row gap-2 ">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => setShowCreatePreset(true)}> Create Version </button>
                                <div className="m-0 divider divider-horizontal"></div>
                                <button
                                    className="btn btn-primary"
                                    disabled={isLoadingFromLiveTable}
                                    onClick={() => loadFromLiveTable()}> Load from LiveTable </button>
                            
                            </div>
                        </div>
                    </ExampleManagerTabs>
                </div>
                
                <div className="flex flex-col mt-4 rounded-md bg-base-200">
                    <div className="p-4">
                        
                        <div className="flex gap-4">
                            <div>
                                <label htmlFor="documentType">Document Type</label>
                                {selectedTemplate &&
                                    
                                    <Selectbox
                                        className="w-[255px] "
                                        handleChange={(_, value) => {
                                            saveTemplateDocumentType(value.value)
                                        }}
                                        option={DOCUMENT_TYPES.map(dt => ({
                                            value: dt.id,
                                            label: dt.label,
                                        })).find(dt => dt.value === selectedTemplate?.documentType) || null}
                                        name="docType"
                                        options={[
                                            { value: null, label: 'None' },
                                            ...DOCUMENT_TYPES.map(dt => ({
                                                value: dt.id,
                                                label: dt.label,
                                            })),
                                        ]} />
                                }
                            </div>
                            <div>
                                <label htmlFor="models">
                                    Model
                                </label>
                                <Selectbox
                                    className="w-[255px]"
                                    handleChange={(ev, value) => {
                                        saveModelToVersion(value.value)
                                    }}
                                    name="models"
                                    option={models.map(model => (
                                        {
                                            value: model.id,
                                            label: model.label,
                                        })).find(model => model.value === selectedTemplate?.modelName) || null}
                                    options={[
                                        { value: '', label: 'None' },
                                        ...models.map(model => ({
                                            value: model.id,
                                            label: model.label,
                                        })),
                                    ]} />
                            
                            </div>
                        </div>
                        <div className="flex gap-2 pt-2">
                            
                            <div className={cn('flex flex-col gap-4 w-full', {
                                'flex-1': system === 'value_extractor' || system === 'chainer',
                            })}>
                                <label htmlFor="instruction">Instructions</label>
                                <textarea
                                    id="instruction"
                                    type="textarea"
                                    className="input input-bordered w-full h-[calc(100vh-432px)]"
                                    value={instruction}
                                    onChange={e => setInstruction(e.target.value)}></textarea>
                            </div>
                            
                            {system === 'chainer' && (
                                <div className="flex-1">
                                    <div className="flex flex-row justify-between w-full gap-4 mb-4">
                                        <label htmlFor="definitions"> Definitions </label>
                                    </div>
                                    
                                    <div className="overflow-auto h-[calc(100vh-432px)]">
                                        {chainerConstants.map((definition, idx) => {
                                            return (
                                                <div key={definition?.id} className="p-4 mb-2 rounded bg-base-100">
                                                    <div className="flex justify-around gap-4">
                                                        <div className="flex flex-col w-4/12">
                                                            <label htmlFor="vname"> Tool Name </label>
                                                            <input
                                                                id="vname"
                                                                type="text"
                                                                className="w-full input input-bordered"
                                                                value={definition?.key}
                                                                disabled={true} />
                                                        </div>
                                                        <div className="flex flex-col self-center w-8/12">
                                                            <label htmlFor="def"> Tool Instructions </label>
                                                            <textarea
                                                                id="def"
                                                                className="w-full h-20 input input-bordered"
                                                                value={definition?.definition}
                                                                onChange={e => handleDefinitionChange(
                                                                    idx, definition, e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )}
                            
                            {system === 'value_extractor' && (
                                <div className="flex-1">
                                    <div className="flex flex-row justify-between w-full gap-4 mb-1">
                                        <label htmlFor="definitions"> Definitions </label>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => addNewDefinition()}> <MdAdd /> Add Definition </button>
                                    </div>
                                    
                                    <div className="overflow-auto h-[calc(100vh-432px)]">
                                        {definitions.map((definition, idx) => (
                                            <div key={definition.id || idx} className="p-4 mb-2 rounded bg-base-100">
                                                <div className="flex justify-around gap-4">
                                                    <div className="flex flex-col gap-2">
                                                        <div className="flex flex-col">
                                                            <label htmlFor="vname"> Value Name </label>
                                                            <input
                                                                id="vname"
                                                                type="text"
                                                                className="w-full input input-bordered"
                                                                value={definition.key}
                                                                onChange={e =>
                                                                    handleDefinitionValue('key', idx, e.target.value)}/>
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <label htmlFor="dname"> Display Name </label>
                                                            <input
                                                                id="dname"
                                                                type="text"
                                                                className="w-full input input-bordered"
                                                                value={definition.displayName}
                                                                onChange={e =>
                                                                    handleDefinitionValue('displayName',
                                                                        idx, e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col self-center w-8/12">
                                                        <div className="flex">
                                                            <Toggle label="Visible"
                                                                checked={definition.visible}
                                                                className="gap-2 mb-2 cursor-pointer"
                                                                onChange={visible =>
                                                                    handleDefinitionValue('visible', idx, visible)} />
                                                        </div>
                                                        <label htmlFor="def"> Definition </label>
                                                        <textarea
                                                            id="def"
                                                            className="w-full h-20 input input-bordered"
                                                            value={definition.definition}
                                                            onChange={e =>
                                                                handleDefinitionValue('definition', idx,
                                                                    e.target.value)} />
                                                    </div>
                                                    <button
                                                        onClick={() => {
                                                            if (definition.id) {
                                                                setDefinitionToDelete(definition.id)
                                                            } else {
                                                                setDefinitions(definitions.filter((_, i) => i !== idx))
                                                            }
                                                        }}
                                                        className="self-start text-red-400
                                                            cursor-pointer hover:text-red-600">
                                                        <FaTrash />
                                                    </button>
                                                </div>
                                            </div>
                                        ),
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex justify-end w-full gap-2 rounded-b-md">
                            <button
                                className="btn btn-error"
                                onClick={() => setShouldDeleteTemplate(true)}>
                                Delete
                            </button>
                            <button
                                className="btn btn-primary"
                                onClick={() => createDefinition()}>
                                Update
                            </button>
                        </div>
                    </div>
                
                </div>
            </div>
            
            {shouldDeleteTemplate && (
                <ConfirmationModal
                    confirmButtonText="Delete"
                    confirmButtonClass="btn-error"
                    showModal={shouldDeleteTemplate}
                    showHideModal={() => setShouldDeleteTemplate(false)}
                    title="Delete Template"
                    message={(
                        <div>
                            <div>Are you sure you want to delete the template "{selectedTemplate?.name}"?
                                Be aware if the template is deleted, all examples under this
                                template will be deleted as well.
                            
                            <div className="mt-4">
                                Collections that currently use this template:
                            </div>
                            {templateCollectionsName.length ? (
                                <ul>
                                    {templateCollectionsName.map(tcn => (
                                        <li className="ml-5 list-disc">{tcn}</li>
                                    ))}
                                </ul>
                            ) : <span className="text-slate-400 text-sm">
                                    Currently no collection uses this template.
                            </span>}
                            
                            <div className="mt-4">
                                And these are the organizations that currently use this template:
                            </div>
                            
                            {templateOrganizationsName.length ? (
                                <ul>
                                    {templateOrganizationsName.map(tcn => (
                                        <li className="ml-5 list-disc">{tcn}</li>
                                    ))}
                                </ul>
                            ) : <span className="text-slate-400 text-sm">
                                    Currently no organization uses this template.
                            </span>}
                            </div>
                        </div>
                    )}
                    onConfirm={() => deleteTemplate()} />
            )}
            
            {definitionToDelete && (
                <ConfirmationModal
                    showModal={definitionToDelete}
                    showHideModal={() => setDefinitionToDelete(null)}
                    title="Delete Definition"
                    message="Are you sure you want to delete this definition?"
                    onConfirm={() => deleteDefinition(definitionToDelete)} />
            )}
            
            {showExampleManagerModal && (
                <ExampleModal
                    showExampleManagerModal={showExampleManagerModal}
                    setShowExampleManagerModal={setShowExampleManagerModal}
                    system={system}
                    templateId={templateId} />
            )}
            
            {showCreatePreset && (
                <CreatePresetModal
                    showCreatePreset={showCreatePreset}
                    setShowCreatePreset={setShowCreatePreset}
                    templateId={templateId}
                    system={system} />
            )}
            
            {includeTemplateModal && (
                <CreateTemplateModal
                    includeSubTabModal={includeTemplateModal}
                    setIncludeSubTabModal={setIncludeTemplateModal}
                    system={system} />
            )}
        
        </div >
    )
}

export default DashboardExampleManager
