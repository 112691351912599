
// Globally selected organization has changed
export const EVENT_ORG_CHANGED = 'EVENT_ORG_CHANGED'

// Documents were uploaded to a collection
export const EVENT_COLLECTION_DOCS_UPLOADED = 'EVENT_COLLECTION_DOCS_UPLOADED'

export const EVENTS = [
    EVENT_ORG_CHANGED,
    EVENT_COLLECTION_DOCS_UPLOADED,
]
