import { v4 as uuidV4 } from 'uuid'

const ids = new Set()

const createId = () => {
    
    const nextId = ids.size + 1
    
    ids.add(nextId)
    
    return nextId
    
}

const createPreset = (label, messages, builderFn = {}) => ({
    id: createId(),
    label,
    value: createId(),
    message: Array.isArray(messages) ? messages.join(' ') : messages,
    build: builderFn,
})

const atrUploadPreset = createPreset('ATR Upload', [
    'Choose a PDF file to upload.',
    'Re-use the same transaction ID for all files to merge a split PDF.',
], () => ({
    endpoint: 'atr/upload',
    method: 'POST',
    fields: {
        transactionId: `PARSE_${uuidV4()}`,
    },
    headers: {
        'x-split-pdf-num': '1',
    },
}))

const atrProcessPreset = createPreset('ATR Process', [
    'Re-use the same transaction ID for all files to merge a split PDF.',
], () => ({
    endpoint: 'atr/process',
    method: 'POST',
    fields: {
        transactionId: `PARSE_${uuidV4()}`,
    },
}))

export default [
    atrUploadPreset,
    atrProcessPreset,
]
