import api from '$lib/api'
import { createQueryString } from '@utils'
// import { createQueryString } from '@utils'

export const listDatabaseModels = async () => {
    
    const res = await api.get('/admin/database/models')
    
    return res.data
    
}

/**
 * Syncs one or more database models
 *
 * @param {string[]} modelNames - The names of the models to sync
 * @param {SyncDatabaseModelsOptions} options - Options for syncing the models
 * @returns {Promise<*>}
 */
export const syncDatabaseModels = async (modelNames = [], options = { alter: true }) => {
    
    const res = await api.post('/admin/database/sync', {
        modelNames,
        ...options,
    })
    
    return res.data
    
}

export const listDatabaseTables = async () => {
    
    const res = await api.get('/admin/database/tables')
    
    return res.data
    
}

export const fetchDatabaseTableRows = async (tableName, offset = 0, limit = 10) => {
    
    const paramsObj = {
        offset,
        limit,
    }
    const params = createQueryString(paramsObj)
    
    const res = await api.get(`/admin/database/tables/${tableName}${params}`, {
        offset,
        limit,
    })
    
    return res.data
    
}

export const getDatabaseTableRow = async (tableName, rowId) => {
    
    const res = await api.get(`/admin/database/tables/${tableName}/${rowId}`)
    
    return res.data
    
}
