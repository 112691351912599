import { useRef, useState, useMemo, useEffect, useCallback } from 'react'
import { useWireState, useWireValue } from '@forminator/react-wire'
import * as store from '@/store'
import * as actions from '@actions'
import { filterUploadsByState } from '@/store/files'
import {
    BATCH_UPLOAD_STATE_PENDING,
    BATCH_UPLOAD_STATE_PROCESSING,
    BATCH_UPLOAD_STATE_PROCESSING_COMPLETE,
    BATCH_UPLOAD_STATE_UPLOADING,
    BATCH_UPLOAD_STATE_COMPLETE,
    BATCH_UPLOAD_STATE_FAILED,
    BATCH_UPLOAD_STATES_FINISHED,
} from '@constants/files'
import { calculateTimeRemainingLabel, createPdfPages } from './GlobalUploadsPanel.lib'
import { debounce, randomNumber, simulateUpload, sleep } from '@/utils'
import { toast } from 'react-toastify'
import { PENDO_EVENTS, pendoTrack } from '@utils/pendo'
import { forgetHasPromptedToProcessDocumentId } from '@actions'
import { createSampleUploads } from '@/store'
import { postEvent } from '@utils/eventBus'
import { EVENT_COLLECTION_DOCS_UPLOADED } from '@constants'

// @debug Simulate processing instead of actually processing & uploading files
const SIMULATE_PROCESSING = false

// Simulate even-odd failures for debugging
const SIMULATE_RANDOM_FAILURES = false

// Don't show the completed state in the panel after processing & uploading
// (useful for seeing item states in the panel)
const DISABLE_COMPLETED_STATE = false

let simulatedFailureCount = 0

const GlobalUploadsPanelViewModel = () => {
    
    //region State
    
    const timerRef = useRef()
    
    // @todo @debug
    const [debugFastUploads, setDebugFastUploads] = useState(true)
    
    const [allUploadsComplete, setAllUploadsComplete] = useState(false)
    const [processTimesMs, setProcessTimesMs] = useState([])
    const [uploadTimesMs, setUploadTimesMs] = useState([])
    const [timeRemainingLabel, setTimeRemainingLabel] = useState('')
    
    //endregion State
    
    //region Global State
    
    const user = useWireValue(store.user)
    const currentOrganization = useWireValue(store.currentOrganization)
    
    /**
     * @type {boolean}
     * If the panel is expanded
     */
    const [globalUploadsPanelExpanded, setGlobalUploadsPanelExpanded] = useWireState(store.globalUploadsPanelExpanded)
    
    /** @type {StagedUploadOptions} */
    const [stagedUploadOptions, setStagedUploadOptions] = useWireState(store.stagedUploadOptions)
    
    /**
     * @type {File[]}
     * Staged files from the browser file input
     */
    const [stagedUploadFiles, setStagedUploadFiles] = useWireState(store.stagedUploadFiles)
    
    /** @type {boolean} */
    const hasPendingUploadFiles = useWireValue(store.hasStagedUploadFiles)
    
    /**
     * @type {StagedUploadItem[]}
     * Files that are being uploaded or processed
     */
    const [pendingUploads, setPendingUploads] = useWireState(store.pendingUploads)
    
    /** @type {boolean} */
    const hasPendingUploads = useWireValue(store.hasPendingUploads)
    
    /** @type {StagedUploadItem[]} */
    const pendingUploadsPending = useWireValue(store.pendingUploadsPending)
    
    /** @type {StagedUploadItem[]} */
    const pendingUploadsProcessing = useWireValue(store.pendingUploadsProcessing)
    
    /** @type {StagedUploadItem[]} */
    const pendingUploadsProcessingComplete = useWireValue(store.pendingUploadsProcessingComplete)
    
    /** @type {StagedUploadItem[]} */
    const pendingUploadsUploading = useWireValue(store.pendingUploadsUploading)
    
    /** @type {StagedUploadItem[]} */
    const pendingUploadsComplete = useWireValue(store.pendingUploadsComplete)
    
    /** @type {StagedUploadItem[]} */
    const pendingUploadsFailed = useWireValue(store.pendingUploadsFailed)
    
    /** @type {StagedUploadItem[]} */
    const pendingUploadsQueued = useWireValue(store.pendingUploadsQueued)
    
    /** @type {StagedUploadItem[]} */
    const pendingUploadsActive = useWireValue(store.pendingUploadsActive)
    
    /** @type {StagedUploadItem[]} */
    const pendingUploadsFinished = useWireValue(store.pendingUploadsFinished)
    
    /** @type {StagedUploadItem[]} */
    const pendingUploadsSortedByState = useWireValue(store.pendingUploadsSortedByState)
    
    /** @type {number} */
    const pendingUploadsTotalProgress = useWireValue(store.pendingUploadsTotalProgress)
    
    //endregion Global State
    
    //region Memos
    
    const averageBatchItemTimeMs = useMemo(() => {
        
        const averageProcessTimeMs = processTimesMs.reduce((acc, curr) => acc + curr, 0) / processTimesMs.length
        const averageUploadTimeMs = uploadTimesMs.reduce((acc, curr) => acc + curr, 0) / uploadTimesMs.length
        
        return averageProcessTimeMs + averageUploadTimeMs
        
    }, [processTimesMs, uploadTimesMs])
    
    // @todo This is a pretty rough estimate, but it's good enough for now
    const timeRemaining = useMemo(() => {
        
        if (!averageBatchItemTimeMs || !pendingUploadsActive)
            return { seconds: 0, minutes: 0, hours: 0 }
        
        const seconds = Math.round((averageBatchItemTimeMs * pendingUploadsActive.length) / 1000)
        
        return {
            seconds,
            minutes: Math.floor(seconds / 60),
            hours: Math.floor(seconds / 3600),
        }
        
    }, [averageBatchItemTimeMs, pendingUploadsActive])
    
    //endregion Memos
    
    //region Helper Methods
    
    // @todo @debug keep until GlobalUploadsPanel is stable
    const debugSetMixedStates = () => {
        
        const items = createSampleUploads().map(it => ({
            ...it,
            state: BATCH_UPLOAD_STATES_FINISHED[randomNumber(0, BATCH_UPLOAD_STATES_FINISHED.length - 1)],
        }))
        
        console.log('debugSetMixedStates', items)
        
        setPendingUploads(items)
        setStagedUploadFiles(items.map(it => it.file))
        setAllUploadsComplete(true)
        
    }
    
    /**
     * Resets some general state for the GlobalUploadsPanel
     * 
     * @param {StagedUploadItem} [samplePendingUploads=[]] - Optionally override `samplePendingUploads`
     * @param {File[]} [sampleStagedUploadFiles=[]] - Optionally override `sampleStagedUploadFiles`
     * @param {boolean} [resetStagedUploadOptions] - Optionally override `resetStagedUploadOptions`
     * @param {boolean} [resetStagedUploadFiles] - Optionally override `resetStagedUploadFiles`
     */
    const resetGlobalUploadsPanel = ({
        samplePendingUploads = [],
        sampleStagedUploadFiles = [],
        resetStagedUploadOptions = true,
        resetStagedUploadFiles = true,
    } = {}) => {
        
        setPendingUploads(samplePendingUploads || [])
        setAllUploadsComplete(false)
        
        if (resetStagedUploadFiles)
            setStagedUploadFiles(sampleStagedUploadFiles || [])
        
        setProcessTimesMs([])
        setUploadTimesMs([])
        setTimeRemainingLabel('')
        
        if (resetStagedUploadOptions)
            setStagedUploadOptions({})
        
    }
    
    /**
     * Shows a message to the user about the
     * upload panel with helpful info for the user
     */
    const showUploadPanelInfo = () => {
        
        // @todo use a real modal
        alert('The upload panel shows the queue of files being preprocessed and uploaded.\n\n' +
            'You can continue using the app while the uploads are in progress,' +
            'but do not navigate away or refresh the page until the uploads are complete.')
        
    }
    
    /**
     * Toggles the expanded state of the upload panel
     */
    const toggleGlobalUploadsPanelExpanded = () =>
        setGlobalUploadsPanelExpanded(prev => !prev)
    
    /**
     * Debounces the `setTimeRemainingLabel` method so updates look smoother
     * 
     * @type {function(...[*]): void}
     */
    const debouncedSetTimeRemainingLabel = useMemo(() => debounce(
        newLabel => setTimeRemainingLabel(newLabel), 150), [])
    
    /**
     * Updates the state of a pending upload task to show progress, etc.
     * 
     * @param {StagedUploadItem} item
     * @param {number|null} [max] - Max progress value (default: current max)
     * @param {number|null} [value] - Current progress value (default: current value)
     * @param {string} [state] - Current state (one of `BATCH_UPLOAD_STATES`) (default: current state)
     * @param {Object} [props={}] - Optional additional props
     */
    const updatePendingUpload = (item, max, value, state, props = {}) => {
        
        // console.log('updatePendingUpload', { item, max, value, state })
        
        /* setPendingUploads(prev => [...prev].map(it => {
            
            if (it.file.name === item.file.name)
                return {
                    ...it,
                    ...props,
                    max: (max !== null && max !== undefined) ? max : it.max,
                    value: (value !== null && value !== undefined) ? value : it.value,
                    state: state || it.state || 'preprocessing',
                }
            
            return it
            
        })) */
        
        setPendingUploads(prev => [...prev].map(it => {
            
            let next = it
            
            if (it.file.name === item.file.name)
                next = {
                    ...it,
                    ...props,
                    max: (max !== null && max !== undefined) ? max : it.max,
                    value: (value !== null && value !== undefined) ? value : it.value,
                    state: state || it.state || 'preprocessing',
                }
            
            /* if (props.pageFiles)
                console.log('updatePendingUpload set pageFiles', next) */
            
            return next
            
        }))
        
    }
    
    // @todo actual implementation
    const getValidUploadFiles = useCallback(() => {
        
        // console.log('getValidUploadFiles', stagedUploadFiles)
        
        return [...stagedUploadFiles].map(it => ({
            ...it,
            state: BATCH_UPLOAD_STATE_PENDING,
        }))
        
    }, [stagedUploadFiles])
    
    /**
     * Shows a toast notification that documents have been queued for processing,
     * and updates the user's local credits if realtime is not enabled
     * 
     * @param {number} filesLength - The number of files queued
     * @returns {Promise<void>}
     */
    const notifyDocumentsQueuedForMLProcessing = async filesLength => {
        
        toast.success(`Queued ${filesLength}` +
            `${filesLength === 1 ? ' document' : ' documents'} for processing`)
        
        if (!store.remoteConfig.getValue()?.realtimeEnabled)
            await actions.updateLocalCredits()
        
    }
    
    //endregion Helper Methods
    
    //region Debug Methods
    
    /**
     * @todo @debug keep until GlobalUploadsPanel is stable
     * @param {StagedUploadItem} item
     */
    const debugSimulateProcessSingleFile = async item => {
        
        const startTime = performance.now()
        
        // Simulate splitting N page
        const pageCount = randomNumber(3, 10)
        
        updatePendingUpload(item, pageCount, 0, BATCH_UPLOAD_STATE_PROCESSING, { pageCount })
        
        await simulateUpload(pageCount, value => updatePendingUpload(
            item, pageCount, value, BATCH_UPLOAD_STATE_PROCESSING), {
            minIncrement: 1,
            maxIncrement: 2,
        })
        
        const endTime = performance.now()
        const totalDurationMillis = endTime - startTime
        
        setProcessTimesMs(prev => [...prev, totalDurationMillis])
        
    }
    
    /**
     * @todo @debug keep until GlobalUploadsPanel is stable
     * @param {StagedUploadItem} item
     */
    const debugSimulateUploadSingleFile = async item => {
        
        const startTime = performance.now()
        
        updatePendingUpload(item, item.file.size, 0, BATCH_UPLOAD_STATE_UPLOADING)
        
        await simulateUpload(item.file.size, value => updatePendingUpload(
            item, item.file.size, value, BATCH_UPLOAD_STATE_UPLOADING), {
            minIncrement: debugFastUploads ? item.file.size / 10 : item.file.size / 50,
            maxIncrement: debugFastUploads ? item.file.size / 15 : item.file.size / 25,
        })
        
        console.log('debugSimulateUploadSingleFile', { pageFiles: item.pageFiles })
        
        updatePendingUpload(item, 100, 100, BATCH_UPLOAD_STATE_COMPLETE, {
            documentId: `fake-${Date.now()}`,
        })
        
        const endTime = performance.now()
        const totalDurationMillis = endTime - startTime
        
        setUploadTimesMs(prev => [...prev, totalDurationMillis])
        
    }
    
    //endregion Debug Methods
    
    /**
     * Processes a single file, splitting its pages into images
     * 
     * @param {StagedUploadItem} item
     * @returns {Promise<SplitPdfPageImagesResultPayloadWithDataUri[]>}
     */
    const processSingleFile = async item => {
        
        const startTime = performance.now()
        
        let pageCount = 0
        let pageFiles = []
        
        try {
            
            /** @type {SplitPdfPageImagesResultPayloadWithDataUri[]} */
            pageFiles = await createPdfPages(item.file, (max, value) => {
                pageCount = max
                // Total progress = page count + file size so we don't need to reset for uploads
                updatePendingUpload(item, max, value, BATCH_UPLOAD_STATE_PROCESSING)
            })
            
            console.log('processSingleFile pageFiles', pageFiles)
            
            updatePendingUpload(item, pageCount,
                pageCount, BATCH_UPLOAD_STATE_PROCESSING_COMPLETE, { pageFiles })
            
        } catch (e) {
            
            console.error('processSingleFile', item, e)
            updatePendingUpload(item, pageCount, pageCount, BATCH_UPLOAD_STATE_FAILED)
            
        }
        
        const endTime = performance.now()
        const totalDurationMillis = endTime - startTime
        
        setProcessTimesMs(prev => [...prev, totalDurationMillis])
        
        return pageFiles
        
    }
    
    /**
     * Uploads a single file (and it's pages, if client-side page splitting is enabled)
     * 
     * @param {StagedUploadItem} item - The file to upload
     * @returns {Promise<void>} - The updated item
     */
    const uploadSingleFile = async item => {
        
        const startTime = performance.now()
        const { collectionId, runsheetId, fundingSource } = stagedUploadOptions
        
        try {
            
            // Allow simulating failures for debugging
            if (SIMULATE_RANDOM_FAILURES)
                if ((simulatedFailureCount % 2) === 0) {
                    simulatedFailureCount++
                    // noinspection ExceptionCaughtLocallyJS
                    throw new Error('Simulated random failure')
                } else {
                    simulatedFailureCount++
                }
            
            const pageCount = item.pageFiles?.length || 0
            const totalProgress = pageCount + item.file.size
            
            updatePendingUpload(item, item.file.size, 0, BATCH_UPLOAD_STATE_UPLOADING)
            
            const documentId = await actions.postFile(
                item.file,
                item.pageFiles,
                collectionId,
                {
                    name: item.file.name,
                    description: item.description,
                },
                runsheetId,
                // Total progress = page count + file size so we don't need to reset for uploads
                value => updatePendingUpload(item, value + pageCount, pageCount, BATCH_UPLOAD_STATE_UPLOADING),
                value => updatePendingUpload(item, null, value, BATCH_UPLOAD_STATE_UPLOADING),
                item.shouldAutoMLProcessDocument,
                fundingSource,
                item.pageFiles?.length || undefined)
            
            // @todo seems like the progress event fires after upload already completes
            await sleep(500)
            
            updatePendingUpload(item, totalProgress, totalProgress, BATCH_UPLOAD_STATE_COMPLETE, {
                documentId,
            })
            
            pendoTrack(PENDO_EVENTS.DOC_UPLOAD, {
                collectionId,
                documentId,
                fileName: item.file.name,
                fileSize: item.file.size,
            })
            
            if (item.shouldAutoMLProcessDocument) {
                forgetHasPromptedToProcessDocumentId(documentId)
                pendoTrack(PENDO_EVENTS.DOC_PROCESS, { documentId })
            }
            
        } catch (e) {
            
            console.error('uploadSingleFile', item, e)
            updatePendingUpload(item, item.file.size, item.file.size, BATCH_UPLOAD_STATE_FAILED) 
            
        }
        
        const endTime = performance.now()
        const totalDurationMillis = endTime - startTime
        
        setUploadTimesMs(prev => [...prev, totalDurationMillis])
        
    }
    
    /**
     * Completes the upload flow, showing the success/errors in the panel,
     * adds documents to runsheets, if relevant, and updates the user's local credits
     * 
     * @returns {Promise<void>}
     */
    const completeUploadFlow = async () => {
        
        // Don't show the completed state in the panel after processing & uploading
        // (useful for seeing item states in the panel)
        if (DISABLE_COMPLETED_STATE) return
        
        setAllUploadsComplete(true)
        
        const { runsheetId, runsheetTabId, fundingSource } = stagedUploadOptions
        const documents = filterUploadsByState(pendingUploads, BATCH_UPLOAD_STATES_FINISHED)
        
        console.log('completeUploadFlow', { pendingUploads, documents })
        
        const documentsForMLProcessing = documents.filter(it => it.shouldAutoMLProcessDocument)
        const documentsNotForMLProcessing = documents.filter(it => !it.shouldAutoMLProcessDocument)
        
        if (runsheetId)
            await Promise.all([
                actions.addRunsheetDocument(fundingSource, {
                    runsheetId,
                    documentIds: documentsForMLProcessing.map(it => it.id),
                    shouldAddLocked: true,
                    runsheetTabId,
                }),
                actions.addRunsheetDocument(fundingSource, {
                    runsheetId,
                    documentIds: documentsNotForMLProcessing.map(it => it.id),
                    shouldAddLocked: false,
                    runsheetTabId,
                }),
            ])
        
        if (documentsForMLProcessing.length)
            await notifyDocumentsQueuedForMLProcessing(documentsForMLProcessing.length)
        
        postEvent(EVENT_COLLECTION_DOCS_UPLOADED)
        
    }
    
    /**
     * Processes the next item for page splitting, if enabled
     * 
     * @param {StagedUploadItem} item
     * @returns {Promise<void>}
     */
    const processNextProcessItem = async item => {
        
        // No more pending items in the queue, so we're done
        // The `processNextUploadItem` method will handle completing the flow
        if (!item) return
        
        // console.log('processNextProcessItem: item', item)
        
        // @todo @featureflag
        let pageFiles = null
        const { clientPdfPageSplittingEnabled } = store.remoteConfig.getValue()
        
        if (clientPdfPageSplittingEnabled) {
            updatePendingUpload(item, 1, 1, BATCH_UPLOAD_STATE_PROCESSING)
            // The `processSingleFile` method will update the status to
            // `BATCH_UPLOAD_STATE_PROCESSING_COMPLETE` on success, and
            // also set the `pageFiles` property on the item so we can upload
            pageFiles = await (SIMULATE_PROCESSING
                ? debugSimulateProcessSingleFile(item)
                : processSingleFile(item))
        } else {
            // Otherwise set it to complete, since we're not splitting the file client-side
            updatePendingUpload(item, 1, 1, BATCH_UPLOAD_STATE_PROCESSING_COMPLETE, { pageFiles: [] })
        }
        
        if (clientPdfPageSplittingEnabled && !pageFiles)
            return console.warn('processNextProcessItem: Failed to split PDF into pages', { item, pageFiles })
        
    }
    
    /**
     * Processes the next item for uploading
     * 
     * @param {StagedUploadItem} item
     * @returns {Promise<void>}
     */
    const processNextUploadItem = async item => {
        
        if (!item) return
        
        // console.log('processNextUploadItem: item', item)
        
        // Sanity check to make sure `pageFiles` were properly set after page splitting
        const { clientPdfPageSplittingEnabled } = store.remoteConfig.getValue()
        
        if (clientPdfPageSplittingEnabled && !item.pageFiles?.length) {
            console.warn('processNextUploadItem: pageFiles were not set after page splitting', item)
            return updatePendingUpload(item, 1, 1, BATCH_UPLOAD_STATE_FAILED)
        }
        
        await (SIMULATE_PROCESSING
            ? debugSimulateUploadSingleFile(item)
            : uploadSingleFile(item))
        
    }
    
    /**
     * Starts the batch process & upload flow
     * 
     * @returns {void}
     */
    const batchUploadFiles = () => {
        
        // @todo @debug
        simulatedFailureCount = 0
        
        resetGlobalUploadsPanel({
            samplePendingUploads: getValidUploadFiles(),
            resetStagedUploadOptions: false,
            resetStagedUploadFiles: false,
        })
        
    }
    
    // Main worker queue loop that runs new jobs when there's enough slots available
    useEffect(() => {
        
        // No work to do
        if (!pendingUploads.length) return
        
        const processQueue = () => {
            
            if (pendingUploadsFinished.length === pendingUploads.length) {
                clearInterval(timerRef.current)
                return completeUploadFlow()
            }
            
            //
            // Next processing item
            //
            const processingActiveCount = pendingUploads.filter(it =>
                it.state === BATCH_UPLOAD_STATE_PROCESSING).length
            
            // Maximum number of active uploads that can be processing
            const maxActiveProcessing = store.remoteConfig.getValue()?.web?.maxActiveUploadProcessing ?? 3
            
            // Maximum number of active uploads that can be uploading
            const maxActiveUploading = store.remoteConfig.getValue()?.web?.maxActiveUploadUploading ?? 3
            
            if (processingActiveCount < maxActiveProcessing) {
                
                const availableSlots = maxActiveProcessing - processingActiveCount
                const nextProcessingIds = []
                
                for (let i = 0; i < availableSlots; i++) {
                    
                    /** @type {StagedUploadItem} */
                    const item = pendingUploads.find(it => (
                        !nextProcessingIds.includes(it.id)
                        && it.state === BATCH_UPLOAD_STATE_PENDING
                    ))
                    
                    if (item?.id)
                        nextProcessingIds.push(item.id)
                    
                    processNextProcessItem(item)
                        .catch(e => console.error('processQueue tick processNextProcessItem', e))
                    
                }
                
            } else {
                
                console.log('processQueue tick no process work to do')
                
            }
            
            //
            // Next upload item
            //
            const uploadActiveCount = pendingUploads.filter(it =>
                it.state === BATCH_UPLOAD_STATE_UPLOADING).length
            
            if (uploadActiveCount < maxActiveUploading) {
                
                const availableSlots = maxActiveUploading - uploadActiveCount
                const nextUploadingIds = []
                
                for (let i = 0; i < availableSlots; i++) {
                    
                    /** @type {StagedUploadItem} */
                    const item = pendingUploads.find(it => (
                        !nextUploadingIds.includes(it.id)
                        && it.state === BATCH_UPLOAD_STATE_PROCESSING_COMPLETE
                    ))
                    
                    if (item?.id)
                        nextUploadingIds.push(item.id)
                    
                    processNextUploadItem(item)
                        .catch(e => console.error('processQueue tick processNextUploadItem', e))
                    
                }
                
            } else {
                
                console.log('processQueue tick no upload work to do', {
                    uploadActiveCount,
                    maxActiveUploading,
                    stateUploading: pendingUploads.filter(it =>
                        it.state === BATCH_UPLOAD_STATE_UPLOADING),
                })
                
            }
            
        }
        
        clearInterval(timerRef.current)
        
        timerRef.current = setInterval(processQueue, 500)
        
        return () => clearInterval(timerRef.current)
        
    }, [pendingUploads, pendingUploadsFinished])
    
    // Update the time remaining label, but only every few seconds so it's smoother
    useEffect(() => {
        
        // Update the time remaining label, but only every few seconds
        debouncedSetTimeRemainingLabel(calculateTimeRemainingLabel(timeRemaining))
        
    }, [timeRemaining, debouncedSetTimeRemainingLabel])
    
    // Automatically start processing & uploading files when any are staged
    useEffect(() => {
        
        // Automatically start processing & uploading files when any are staged
        if (stagedUploadFiles.length)
            batchUploadFiles()
        
    }, [stagedUploadFiles])
    
    return {
        
        debugFastUploads,
        setDebugFastUploads,
        
        // State
        allUploadsComplete,
        setAllUploadsComplete,
        
        // Global State
        user,
        currentOrganization,
        globalUploadsPanelExpanded,
        setGlobalUploadsPanelExpanded,
        toggleGlobalUploadsPanelExpanded,
        stagedUploadOptions,
        setStagedUploadOptions,
        stagedUploadFiles,
        setStagedUploadFiles,
        hasPendingUploadFiles,
        pendingUploads,
        setPendingUploads,
        hasPendingUploads,
        pendingUploadsPending,
        pendingUploadsProcessing,
        pendingUploadsProcessingComplete,
        pendingUploadsUploading,
        pendingUploadsComplete,
        pendingUploadsFailed,
        pendingUploadsQueued,
        pendingUploadsActive,
        pendingUploadsFinished,
        pendingUploadsSortedByState,
        pendingUploadsTotalProgress,
        
        // Memos
        averageBatchItemTimeMs,
        timeRemaining,
        timeRemainingLabel,
        
        // Methods
        resetGlobalUploadsPanel,
        showUploadPanelInfo,
        processSingleFile,
        uploadSingleFile,
        batchUploadFiles,
        
        debugSetMixedStates,
        
    }
}

export default GlobalUploadsPanelViewModel
