import cn from 'classnames'

import { Combobox } from '@headlessui/react'
import { LuChevronsUpDown } from 'react-icons/lu'
import { MdCheck } from 'react-icons/md'

const AutoCompleteDropdown = ({
    items,
    setQuery,
    selectedItem,
    setSelectedItem,
    displayValueKey,
    isLoading,
    onChange,
    onClose,
    label = '',
    placeholder = 'Search...',
}) => {
    
    return (
        
        <div className="relative w-72">
            
            <Combobox
                value={selectedItem}
                onChange={item => {
                    
                    if (onChange)
                        onChange(item)
                    else
                        setSelectedItem(item)
                    
                }}
                onClose={() => onClose?.()}>
                
                <div className="relative">
                    
                    <Combobox.Input
                        className="w-full rounded-md border border-base-300 bg-base-200 py-2 pl-3 pr-10 shadow-sm
                        focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                        aria-label={label}
                        onChange={e => setQuery(e.target.value)}
                        displayValue={item => item?.[displayValueKey] || ''}
                        placeholder={placeholder} />
                    
                    <Combobox.Button
                        className={cn('absolute inset-y-0 right-0 group rounded-r-md px-2 focus:outline-none', {
                            hidden: !isLoading,
                        })}>
                        <span className="loading loading-spinner loading-xs" />
                    </Combobox.Button>
                    
                    <Combobox.Button
                        className={cn(
                            'absolute inset-y-0 right-0 group',
                            'empty:invisible rounded-r-md px-2 focus:outline-none', {
                                hidden: isLoading,
                            },
                        )}>
                        <LuChevronsUpDown className="h-5 w-5 text-base-300" aria-hidden="true" />
                    </Combobox.Button>
                
                </div>
                
                {items.length > 0 && (
                    <Combobox.Options
                        className="absolute top-0 inset-x-0 empty:invisible max-h-60
                            w-[var(--input-width)] [--anchor-gap:var(--spacing-1)]
                            transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0
                            rounded-md bg-base-200
                            py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        anchor="bottom">
                        
                        {items.map(it => (
                            <Combobox.Option
                                key={it.id}
                                value={it}
                                className="flex justify-between items-center gap-4
                                    py-2 px-3 text-base-content data-[focus]:bg-accent data-[selected]:bg-accent">
                                {({ selected }) => (<>
                                    <span className={cn('block truncate', {
                                        'font-semibold': selected,
                                        'font-normal': !selected,
                                    })}>
                                        {it[displayValueKey]}
                                    </span>
                                    <MdCheck className={cn('size-5', {
                                        invisible: !selected,
                                    })} />
                                </>)}
                            </Combobox.Option>
                        ))}
                    
                    </Combobox.Options>
                )}
            
            </Combobox>
        
        </div>
        
    )
    
}

export default AutoCompleteDropdown
