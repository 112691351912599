import * as store from '@store'

export const addFailedChainTry = runsheetOrTabId => {
    
    const chainData = { ...store.runsheetTriedChaining.getValue() }
    
    chainData[runsheetOrTabId] = true
    store.runsheetTriedChaining.setValue(chainData)
    
}

export const removeFailedChainTry = runsheetOrTabId => {
    
    const chainData = { ...store.runsheetTriedChaining.getValue() }
    
    delete chainData[runsheetOrTabId]
    store.runsheetTriedChaining.setValue(chainData)
    
}