import { createSelector, createWire } from '@forminator/react-wire'
import { createPersistedWire } from 'react-wire-persisted'
import { keys, storeInitialValues } from '@constants'

export const runsheets = createWire(storeInitialValues.runsheets)
export const currentRunsheet = createWire(null)

export const runsheetTabs = createSelector({
    get: ({ get }) => [
        { id: '0', name: 'Main', tabOrder: 0, joins: [] },
        ...(get(currentRunsheet)?.tabs || []),
    ],
})

export const titleBreakdown = createWire(null)

export const titlePartyGroups = createSelector({
    get: ({ get }) => get(titleBreakdown)?.groups ?? {},
})

export const uniqueParties = createWire(null)

export const runsheetHeaders = createPersistedWire(keys.runsheetHeaders, [])

export const numberDisplayType = createPersistedWire(keys.numberDisplayType, 'percent')

export const runsheetOrder = createPersistedWire(keys.runsheetOrder, null)

export const exportRunsheetSelectedTemplate = createPersistedWire(keys.exportRunsheetSelectedTemplate, null)

export const runsheetDocumentValue = createWire(null)

export const docsMissingForChainer = createWire({})

export const runsheetTriedChaining = createWire({})
