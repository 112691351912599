import { createWire, createSelector } from '@forminator/react-wire'
import { baseUrl } from '@api/ParseAPI'
import * as Ably from 'ably'
import * as userStore from '@store/user'

class AblyClientSingleton {
    constructor() {
        this.client = null
        this.jwt = null
        this.token = null
    }
    
    initialize(user) {
        if (!user?.ably) return null
        
        const jwt = localStorage.getItem('jwt')
        const tokenDetails = user.ably.token
        
        if (!tokenDetails?.token) return null
        
        if (this.client && this.jwt === jwt && this.token === tokenDetails.token) {
            return this.client
        }
        
        this.jwt = jwt
        this.token = tokenDetails.token
        
        /** @type {Ably.Types.ClientOptions} */
        const options = {
            token: tokenDetails.token,
            clientId: tokenDetails.clientId,
            authUrl: `${baseUrl}/realtime/auth`,
            authMethod: 'POST',
            authHeaders: {
                'x-api-key': jwt,
                'x-ably-token-only': true,
            },
            queryTime: process.env.NODE_ENV !== 'production',
        }
        
        this.client = new Ably.Realtime(options)
        
        if (process.env.NODE_ENV === 'development') {
            console.log('store/realtime: Ably instance created', this.client)
        }
        
        return this.client
    }
}

const ablySingleton = new AblyClientSingleton()

/** @type {ReadOnlyWire<Ably.Realtime>} */
export const ably = createSelector({
    get: ({ get }) => {
        const user = get(userStore.user)
        
        return ablySingleton.initialize(user)
    },
})

/** @type {Wire<RealtimeConnectionState>} */
export const realtimeConnectionState = createWire({
    state: null,
    isConnected: false,
})

export const realtimeUserPresenceCount = createWire(0)
export const realtimeOrganizationPresenceCount = createWire(0)
