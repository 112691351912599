import { useState, useCallback } from 'react'

const createItems = count => Array(count)
    .fill(null)
    .map(() => ({ 'key1':'' }))

const useRepeatableViewModel = (initialCount = 1, {
    preventRemovingLastItem = true,
} = {}) => {
    
    const [items, setItems] = useState(createItems(initialCount))
    
    const onAddItemClick = () => setItems(prev => [
        ...prev, {
            [`key${prev.length + 1}`]: '',
        },
    ])
    
    const onRemoveItemClick = useCallback(index => {
        
        if (items.length === 1 && preventRemovingLastItem)
            setItems(createItems(initialCount))
        else
            setItems(items.filter((_, i) => i !== index))
        
    }, [items])
    
    const onItemChange = (index, eKey, eVal) => setItems(prev => {
        
        const next = [ ...prev ]
        
        let key = Object.keys(prev[index])[0]
        let val = prev[index][key]
        
        if (eKey?.target?.value?.length > 0)
            key = eKey.target.value
        
        if (eVal?.target?.value?.length > 0)
            val = eVal.target.value
        
        next[index] = {
            [key]: val,
        }
        
        return next
        
    })
    
    return {
        
        items,
        setItems,
        
        onAddItemClick,
        onRemoveItemClick,
        onItemChange,
        
    }
    
}

export default useRepeatableViewModel
