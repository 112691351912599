// import Logger from '@utils/log'
import * as store from '@store'
import ParseAPI from '@api/ParseAPI'
import { saveMergedUserLocal } from '@store'

// const log = new Logger('actions/organizations')

export const checkOrgExists = async name => {
    
    const res = await ParseAPI.post('orgs/exists', {
        name,
    })
    
    return res.data?.exists || false
    
}

export const createOrg = async name => {
    
    const res = await ParseAPI.post('orgs', {
        name,
    })
    
    store.arrayUpsertById('organizations', res.data, (prev, next) => ({
        ...prev,
        ...next,
    }))
    
    // i also need to update the user organizations
    const user = store.user.getValue()
    
    const newUser = {
        ...user, 
        organizations: [...user.organizations, res.data],
    }
    
    saveMergedUserLocal(newUser)
    
    return res.data
    
}

export const fetchOrgs = async () => {
    
    if (store.isFetchingOrgs.getValue()) return
    
    store.isFetchingOrgs.setValue(true)
    
    const res = await ParseAPI.get('orgs')
    
    store.organizations.setValue(res.data)
    
    store.isFetchingOrgs.setValue(false)
    
    return res.data
    
}

export const fetchOrgById = async (id, setCurrentOrganization = false) => {
    
    const res = await ParseAPI.get(`orgs/${id}`)
    
    store.arrayUpsertById('organizations', res.data, (prev, next) => ({
        ...prev,
        ...next,
    }))
    
    const currentOrganization = store.currentOrganization.getValue()
    const credits = store.credits.getValue()
    
    if (setCurrentOrganization)
        store.currentOrganization.setValue(res.data)
    
    if (currentOrganization?.id === res.data.id)
        store.credits.setValue({ ...credits, organization: res.data.credits })
    
    return res.data
    
}

export const fetchOrgWithLabels = async id => {
    
    const res = await ParseAPI.get(`orgs/${id}/labels`)
    
    return res.data
    
}

export const updateOrg = async (id, data) => {
    
    const res = await ParseAPI.put(`orgs/${id}`, data)
    
    store.arrayUpsertById('organizations', res.data, (prev, next) => ({
        ...prev,
        next,
    }))
    
    return res.data
    
}

export const deleteOrg = async id => {
    
    const res = await ParseAPI.delete(`orgs/${id}`)
    
    store.organizations.setValue(
        store.organizations.getValue()
            .filter(it => it.id !== id),
    )
    
    // i also need to delete the user organizations
    const user = store.user.getValue()
    const idx = user.organizations.findIndex(org => org.id === id)
    
    user.organizations.splice(idx, 1)
    
    const updateUser = {
        ...user, 
        organizations: [...user.organizations],
    }
    
    saveMergedUserLocal(updateUser)
    store.currentOrganization.setValue(updateUser.organizations[0])
    
    return res.data
    
}

export const getCountCollectionAndRunsheet = async id => {
    
    const res = await ParseAPI.get(`orgs/${id}/count`)
    
    return res.data
    
}

export const addMemberToOrg = async (orgId, memberEmail) => {
    
    const res = await ParseAPI.post(`orgs/${orgId}/members`, {
        memberEmail,
    })
    
    await fetchOrgById(orgId, true)
    
    return res.data
    
}

export const updateMemberInOrganization = async (orgId, memberId, memberRole) => {
    
    const res = await ParseAPI.put(`orgs/${orgId}/members/${memberId}`, {
        memberRole,
    })
    
    await fetchOrgById(orgId, true)
    
    return res.data
    
}

export const inviteMemberToOrg = async (
    orgId,
    firstName,
    lastName,
    memberEmail,
) => {
    
    const res = await ParseAPI.post(`orgs/${orgId}/members/invite`, {
        firstName,
        lastName,
        memberEmail,
    })
    
    await fetchOrgById(orgId)
    
    return res.data
    
}

export const removeMemberFromOrg = async (orgId, memberId) => {
    
    const res = await ParseAPI.delete(`orgs/${orgId}/members/${memberId}`)
    
    await fetchOrgById(orgId)
    
    return res.data
    
}

export const requestToJoinOrg = async organizationName => {
    
    const res = await ParseAPI.post('orgs/request', {
        organizationName,
    })
    
    return res.data
    
}

export const getOrgJoinRequestsForUser = async () => {
    
    const res = await ParseAPI.get('users/orgrequests')
    
    return res.data
    
}

export const approveOrganizationJoinRequest = async (orgId, requestId) => {
    
    const res = await ParseAPI.put(`orgs/${orgId}/requests`, {
        requestId,
    })
    
    return res.data
    
}

export const deleteOrganizationJoinRequest = async (orgId, requestId) => {
    
    const res = await ParseAPI.delete(`orgs/${orgId}/requests/${requestId}`)
    
    return res.data
    
}

export const findOrgByName = async (name = '') => {
    if (name.length < 3) return []
    
    const res = await ParseAPI.post('orgs/lookup', { name })
    
    return res.data
}

export const saveMergedCurrentOrgLocal = updatedOrg => {
    
    const prev = store.currentOrganization.getValue() || {}
    
    store.currentOrganization.setValue({ ...prev, ...updatedOrg })
    
}
