import {
    BATCH_UPLOAD_STATE_COMPLETE,
    BATCH_UPLOAD_STATE_FAILED,
    BATCH_UPLOAD_STATE_PENDING,
    BATCH_UPLOAD_STATES_ACTIVE,
} from '@constants'
import cn from 'classnames'
import { MdUploadFile, MdPending, MdCheckCircle, MdOutlineErrorOutline } from 'react-icons/md'

/**
 * Table of staged uploads
 *
 * @param {StagedUploadItem[]} stagedUploadItems - Staged upload payloads
 * @returns {JSX.Element}
 * @constructor
 */
const GUPFilesTable = ({
    stagedUploadItems,
}) => {
    
    const calculateProgress = item =>
        item.max + item.value === 0 ? 0
            : Math.round((item.value / item.max) * 100)
    
    return (
        
        <table className="table w-100">
            <tbody>
                {stagedUploadItems.map((it, i) => {
                    
                    const progress = calculateProgress(it)
                    
                    return (
                        
                        <tr key={`upload-progress-${i}-${it.file.name}`}>
                            
                            <td className="p-0 px-4">
                                <div className="flex gap-2 items-center">
                                    <MdUploadFile className="text-xl" />
                                    <span
                                        className={cn(
                                            'inline-block',
                                            'max-w-[160px]',
                                            'whitespace-nowrap',
                                            'overflow-hidden',
                                            'text-ellipsis',
                                        )}>
                                        {it.file.name}
                                    </span>
                                </div>
                            </td>
                            
                            <td>
                                <div className="flex justify-end items-center content-center">
                                    {BATCH_UPLOAD_STATES_ACTIVE.includes(it.state) && (
                                        <div
                                            className={cn('radial-progress mr-1 bg-base-200 text-xs',
                                                'transition-all duration-300 ease-in-out', {
                                                    'text-secondary': it.value !== it.max,
                                                    'text-primary': it.value === it.max,
                                                    'text-error': it.state === BATCH_UPLOAD_STATE_FAILED,
                                                })}
                                            style={{
                                                '--value': progress,
                                                '--size': '1.3rem',
                                                '--thickness': '3px',
                                            }}
                                            aria-valuenow={progress}
                                            role="progressbar" />
                                    )}
                                    {it.state === BATCH_UPLOAD_STATE_PENDING && (
                                        <MdPending className="text-xl" />
                                    )}
                                    {it.state === BATCH_UPLOAD_STATE_COMPLETE && (
                                        <MdCheckCircle className="text-xl text-success" />
                                    )}
                                    {it.state === BATCH_UPLOAD_STATE_FAILED && (
                                        <MdOutlineErrorOutline className="text-xl text-error" />
                                    )}
                                
                                </div>
                            
                            </td>
                        
                        </tr>
                        
                    )
                    
                })}
            </tbody>
        </table>
        
    )
    
}

export default GUPFilesTable
