import { memo, useState } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { MdCheckCircleOutline, MdKeyboardArrowUp, MdUploadFile } from 'react-icons/md'

const GUPUploadsComplete = ({
    collectionId,
    pendingUploads,
    pendingUploadsFailed,
    onCollectionLinkClick,
}) => {
    
    const [failedFilesExpanded, setFailedFilesExpanded] = useState(false)
    
    return (
        
        <div className="flex flex-col justify-center items-center gap-4 my-4 pb-4 text-center">
            
            <MdCheckCircleOutline className={cn('text-5xl', {
                'text-success': pendingUploadsFailed.length === 0,
                'text-warning': pendingUploadsFailed.length !== 0,
            })} />
            
            <p>All uploads complete.</p>
            
            <p className="flex justify-center gap-1">
                <span>You can</span>
                <Link
                    className="link link-secondary"
                    to={`/collection/${collectionId}/browse?q=`}
                    onClick={onCollectionLinkClick}>
                    click here
                </Link>
                <span>to view your collection.</span>
            </p>
            
            {pendingUploadsFailed.length > 0 && (
                <div className="w-full px-4 py-2 text-sm">
                    <div role="alert" className="alert alert-error bg-error/20 border-error/20
                        px-4 py-2 flex flex-col items-start gap-2">
                        
                        <div className="flex justify-between items-center gap-2 w-full">
                            <span>
                                {pendingUploadsFailed.length}
                                {' '}
                                of {pendingUploads.length} documents failed to upload.
                            </span>
                            <button
                                className="btn btn-sm btn-square btn-ghost"
                                onClick={() => setFailedFilesExpanded(!failedFilesExpanded)}>
                                <MdKeyboardArrowUp className={cn('text-2xl text-base-content', {
                                    'rotate-180': failedFilesExpanded,
                                })} />
                            </button>
                        </div>
                        
                        {failedFilesExpanded && (
                            <ul className="w-full max-h-[8rem] overflow-auto">
                                {pendingUploadsFailed.map(it => (
                                    <li key={it.file.name} className="flex items-center gap-2">
                                        <MdUploadFile className="text-xl" />
                                        <span>{it.file.name}</span>
                                    </li>
                                ))}
                            </ul>
                        )}
                    
                    </div>
                </div>
            )}
        
        </div>
        
    )
    
    
}

export default memo(GUPUploadsComplete)
