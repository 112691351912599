import { useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { debounceAsync } from '@utils'
import * as actions from '$actions'

const fetchOrgs = debounceAsync(async (query, offset, limit) => {
    
    try {
        
        const res = await actions.getOrganizations(query, offset, limit, false)
        
        return res.data
        
    } catch (e) {
        
        console.error('fetchOrgs', e)
        
    }
    
}, 1000)

const useOrgsSearch = ({
    limit = 100,
    ...props
} = {}) => {
    
    const [query, setQuery] = useState('')
    
    const { data: orgs, error, isLoading, refetch } = useQuery({
        queryKey: ['orgs', query, limit],
        queryFn: () => fetchOrgs(query, 0, limit),
        enabled: true,
        retry: 0,
        ...props,
    })
    
    const hasOrgs = useMemo(() => orgs?.data?.length > 0, [orgs])
    
    return {
        
        // State
        query,
        setQuery,
        
        // Memos
        hasOrgs,
        
        // Derived State
        orgs,
        error,
        isLoading,
        refetch,
        
    }
    
}

export default useOrgsSearch
