import { useState, useEffect, useCallback } from 'react'
import { useWireState, useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'
import * as appActions from '@actions'
import cn from 'classnames'

import Modal from '$components/Modal'
import {
    FaPlus,
    FaMinus,
} from 'react-icons/fa'

const ManageOrgCreditsModal = ({
    className,
    onOrgUpdated,
    ...props
}) => {
    
    const [credits, setCredits] = useState(0.00)
    
    const [open, setOpen] = useWireState(store.modalManageOrgCreditsOpen)
    const [org, setOrg] = useWireState(store.modalManageOrgCreditsOrg)
    const remoteConfig = useWireValue(store.remoteConfig)
    
    const onUpdateCreditsClick = useCallback(async () => {
        
        try {
            const res = await actions.updateOrganizationCredits(org.id, {
                credits,
            })
            
            if (!remoteConfig?.realtimeEnabled) await appActions.updateLocalCredits()
            
            onOrgUpdated(res.data)
            
        } catch (e) {
            
            // @todo show error
            console.error('onUpdateCreditsClick', e)
            
        }
        
    }, [org, credits])
    
    useEffect(() => {
        
        if (!org) return
        
        setCredits(org.credits ?? 0)
        
    }, [org])
    
    if (!org) return null
    
    return (
        
        <Modal
            className={className}
            open={open}
            setOpen={value => {
                if (!value) setOrg(null)
                setOpen(value)
            }}
            title="Edit User Credits"
            actionLabel="UPDATE CREDITS"
            actionOnClick={onUpdateCreditsClick}
            {...props}>
            
            <div className="flex flex-col justify-center text-center">
                
                <div className="">
                    <div className="text-base">{org?.name}</div>
                    <div className="mt-4 text-base">
                        Current balance: ${(org?.credits ?? 0).toFixed(2)}
                    </div>
                </div>
                
                <div className="flex items-center content-center justify-between mx-5 mt-10">
                    
                    <button
                        className="text-3xl text-red-100"
                        onClick={() => setCredits(Math.max(credits - 50, 0))}>
                        <FaMinus className="inline text-base" /> 50
                    </button>
                    
                    <div className="p-3 mx-auto text-center rounded w-min ring-2">
                        <div className="">Balance</div>
                        <input
                            className={cn(
                                'w-48 p-1 m-0 text-5xl text-center bg-transparent',
                                'border-none rounded outline-none ring-0',
                                'focus:border-none focus:outline-none',
                            )}
                            type="number"
                            step={1.0}
                            value={credits}
                            onChange={e => setCredits(parseInt(e.target.value, 10))} />
                    </div>
                    
                    <button
                        className="text-3xl text-green-100"
                        onClick={() => setCredits(credits + 50)}>
                        <FaPlus className="inline text-base" /> 50
                    </button>
                
                </div>
            
            </div>
        
        </Modal>
        
    )
    
}

export default ManageOrgCreditsModal
