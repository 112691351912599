import usePaymentRequiredFlowViewModel from './PaymentRequiredFlowViewModel'
import cn from 'classnames'

import CustomModal from '@/components/shared/CustomModal/CustomModal'
// import Checkbox from '$components/Forms/Checkbox'
import PaymentRequiredFlow from '@components/shared/Dialogs/PaymentRequiredDialog/PaymentRequiredFlow'

import './PaymentRequiredFlow.css'
import usePayments from '@hook/usePayments'
import { ModalFooter } from '../../CustomModal'

/**
 * Base payment dialog
 * All params are passed directly, not read from the global store
 * For a more convenient, automatic global store payment dialog, use `GlobalPaymentRequiredDialog`
 *
 * @param {Object} params
 * @param {String} params.className
 * @param {Boolean} params.open
 * @param {PurchaseType} params.purchaseType
 * @param {Function} params.onConfirmPayment Callback (fundingSource, remainingBalance) => {}
 * @param {Number} [params.itemCount=1] Number of items
 */
const PaymentRequiredDialog = ({
    className,
    open,
    purchaseType,
    itemCount = 0,
    onClose,
    onConfirmPayment,
}) => {
    
    const payments = usePayments()
    
    const vm = usePaymentRequiredFlowViewModel({
        fundingSource: payments.fundingSource,
        balance: payments.balance,
        purchaseType,
        itemCount,
        onClose,
        onConfirmPayment,
    })
    
    return (
        
        <CustomModal
            className={cn('PaymentRequiredDialog max-w-screen-sm', className)}
            open={open}
            modalHandler={vm.closeDialog}
            center>
            
            <div className="modal-header" data-testid="PaymentRequiredDialog-header">
                <h3 className="text-2xl">
                    Order Confirmation
                </h3>
            </div>
            
            <PaymentRequiredFlow
                payments={payments}
                purchaseType={purchaseType}
                itemCount={itemCount}
                onClose={onClose}
                onConfirmPayment={onConfirmPayment} />
            
            <ModalFooter>
                {/* <label className={cn('flex items-center content-center gap-3 m-0', {
                    'opacity-50': vm.price >= 20,
                })}>
                    <Checkbox
                        checked={!vm.shouldWarn}
                        disabled={vm.price >= 20}
                        onChange={() => vm.setShouldWarn(!vm.shouldWarn)} />
                    <span className={cn({ 'text-muted': vm.shouldWarn })}>
                        {"Don't remind me again"}
                    </span>
                </label> */}
                <div>
                    <button
                        className="mr-3 btn btn-primary btn-outline"
                        onClick={vm.closeDialog}>
                        Cancel
                    </button>
                    <button
                        className="btn btn-primary"
                        data-test-id="PaymentRequiredDialog-confirm-button"
                        type="submit"
                        disabled={vm.hasInsufficientBalance || vm.loadingPayment}
                        onClick={vm.handleSubmit}>
                        {vm.loadingPayment && <span className="loading loading-spinner loading-xs" />}
                        Continue
                    </button>
                </div>
            </ModalFooter>
        
        </CustomModal>
        
    )
    
}

export default PaymentRequiredDialog
