import Logger from '@utils/log'
import ParseAPI from '@api/ParseAPI'
import * as store from '@store'
import { ATR_TRANSACTION_COMPLETED, ATR_VALUE_EXTRACTION_COMPLETED, CAN_PROCESS_ATR } from '@/constants'

const log = new Logger('actions/atr')


export const findAtrDocument = async (transactionId, packetName) => {
    
    const res = await ParseAPI.get(`/pdfs/atrDocument/${transactionId}/${packetName}`,{
        responseType: 'blob',
    })
    
    return res.data
    
}
export const deleteExtract = async extractDocId => {
    
    await ParseAPI.delete(`atr/atrDocumentExtracts/${extractDocId}`)
    
}

export const findExtractDocument = async selectedPktId => {
    
    try {
        
        const res = await ParseAPI.get(`atr/atrDocumentExtracts/${selectedPktId}`)
        
        return res.data
        
    } catch (error) {
        
        log.e(error)
        throw error
        
    }
}

export const updateExtractDocument = async (selectedPktId, extractDocument) => {
    
    try {
        
        const res = await ParseAPI.post(`atr/atrDocumentExtracts/${selectedPktId}`, extractDocument)
        
        return res
        
    } catch (e) {
        
        log.e(e)
        throw e
        
    }
}

export const getPackets = async () => {
    
    try {
        
        const res = await ParseAPI.get('atr')
        
        store.atrPackets.setValue(res.data)
        
    } catch (error) {
        log.e(error)
        throw error
    }
}

export const atrStatuses = async packetIds => {
    
    try {
        
        const res = await ParseAPI.post('atr/status', {
            ids: packetIds,
        })
        
        const pkts = res.data
        
        const packets = store.atrPackets.getValue()
        
        const newPackets = packets.map(p => {
            const foundPkt = pkts.find(pkt => pkt.id === p.id)
            
            if (foundPkt) {
                
                if ([ATR_VALUE_EXTRACTION_COMPLETED, ATR_TRANSACTION_COMPLETED].includes(foundPkt.status)) {
                    return null
                }
                
                if (!CAN_PROCESS_ATR.includes(foundPkt.status) && p.requestProcessing) {
                    delete p.requestProcessing
                }
                
                return { ...p, ...foundPkt }
            }
            
            return p
        }).filter(np => !!np)
        
        store.atrPackets.setValue(newPackets)
        
        return res.data
        
    } catch (error) {
        log.e(error)
        throw error
    }
    
}

export const getFileList = async () => {
    
    try {
        
        const res = await ParseAPI.get('atr/folders')
        
        store.atrDirectories.setValue(res.data)
        
        return res.data
        
    } catch (error) {
        log.e(error)
        throw error
    }
}

export const processAtrFile = async packetId => {
    
    try {
        
        const res = await ParseAPI.post('atr', { packetId })
        
        const packets = store.atrPackets.getValue()
        
        const packetRes = { ...res.data, requestProcessing: true }
        
        const idx = packets.findIndex(pkt => pkt.id === packetRes.id)
        
        packets.splice(idx, 1, packetRes)
        
        store.atrPackets.setValue([ ...packets ])
        
    } catch (error) {
        log.e(error)
        throw error
    }
}

export const extractAtrDocuments = async directory => {
    
    try {
        
        const res = await ParseAPI.post('atr/extract', directory)
        
        const packets = store.atrPackets.getValue()
        
        store.atrPackets.setValue([ res.data, ...packets ])
        
    } catch (error) {
        log.e(error)
        throw error
    }
}

export const getAtrPacket = async transactionId => {
    
    try {
        
        const res = await ParseAPI.get(`atr/${transactionId}`)
        
        return res.data
        
    } catch (error) {
        
        log.e(error)
        throw error
        
    }
}