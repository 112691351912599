import { useState, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import * as actions from '$actions'

const useDatabaseBrowserViewModel = () => {
    
    const [table, setTable] = useState('')
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(50)
    const [selectedRowId, setSelectedRowId] = useState(null)
    
    //region Queries
    
    const {
        data: tables,
        error: tablesError,
        isLoading: tablesIsLoading,
    } = useQuery({
        queryKey: ['tables'],
        queryFn: actions.listDatabaseTables,
        retry: 0,
    })
    
    const {
        data: tableRows,
        error: tableRowsError,
        isLoading: tableRowsIsLoading,
    } = useQuery({
        queryKey: ['tableRows'],
        queryFn: () => actions.fetchDatabaseTableRows(table),
        enabled: table?.length > 0,
        retry: 0,
    })
    
    const {
        data: selectedRow,
        error: selectedRowError,
        isLoading: selectedRowIsLoading,
    } = useQuery({
        queryKey: ['selectedRow'],
        queryFn: () => actions.getDatabaseTableRow(table, selectedRowId),
        enabled: selectedRowId?.length > 0,
        retry: 0,
    })
    
    //endregion Queries
    
    const error = useMemo(() => (
        tablesError ||
        tableRowsError ||
        null
    ), [tablesError, tableRowsError])
    
    const isLoading = useMemo(() => (
        tablesIsLoading ||
        tableRowsIsLoading ||
        null
    ), [tablesIsLoading, tableRowsIsLoading])
    
    return {
        
        // State
        table,
        setTable,
        offset,
        setOffset,
        limit,
        setLimit,
        selectedRowId,
        setSelectedRowId,
        
        // Memos
        error,
        isLoading,
        
        // React Query
        tables,
        tablesError,
        tablesIsLoading,
        tableRows,
        tableRowsError,
        tableRowsIsLoading,
        selectedRow,
        selectedRowError,
        selectedRowIsLoading,
        
        // Methods
        
    }
    
}

export default useDatabaseBrowserViewModel
