import useCreateServiceApiKeyModal from './UpsertServiceApiKeyModalViewModel'
import Modal from '$components/Modal'
import { FaMinus, FaPlus, FaTimes } from 'react-icons/fa'
import CircleSpinner from '@components/shared/CircleSpinner'
import AutocompleteInput from '@components/shared/AutocompleteInput/AutocompleteInput'
import Repeatable from '@components/shared/Repeatable/Repeatable'

const RepeatableBlock = ({
    repeatableVm,
    title,
    keyName,
    placeholder,
    items,
}) => (
    
    <div className="p-3 rounded" title={'items: ' + items?.length}>
        <div className="w-auto flex justify-between items-center content-center gap-4">
            <p className="font-bold">
                {title}
            </p>
            <div className="w-auto">
                <button
                    className="btn btn-sm btn-square btn-secondary"
                    onClick={repeatableVm.onAddItemClick}>
                    <FaPlus className="!ml-0" />
                </button>
            </div>
        </div>
        <Repeatable viewModel={repeatableVm}>
            {(key, value, i, onKeyChange, onValueChange) => (
                <div key={`${keyName}-${i}`} className="flex items-center content-center gap-2">
                    <input
                        className="w-full"
                        type="text"
                        placeholder={placeholder}
                        value={value}
                        onChange={e => onValueChange(i, e)} />
                    <button
                        className="btn btn-sm btn-square btn-warning"
                        onClick={() => repeatableVm.onRemoveItemClick(i)}>
                        <FaMinus className="!ml-0"/>
                    </button>
                </div>
            )}
        </Repeatable>
    </div>
    
)

const UpsertServiceApiKeyModal = ({
    open,
    setOpen,
    existingServiceApiKey,
    
    ...props
}) => {
    
    const vm = useCreateServiceApiKeyModal({
        open,
        setOpen,
        existingServiceApiKey,
    })
    
    return (
        
        <Modal
            className="CreateServiceApiKeyModal overflow-x-auto"
            contentClassName="flex flex-col gap-4"
            style={{ maxWidth: '80%', maxHeight: '80%' }}
            open={open}
            setOpen={setOpen}
            title={vm.modalLabels.title}
            actionLabel={vm.modalLabels.saveButton}
            actionDisabled={!vm.serviceApiKey.name?.length || vm.loading}
            actionOnClick={vm.onConfirmServiceApiKeyClick}
            showCancel={false}
            {...props}>
            
            {vm.loading && (
                <div className="w-full">
                    <CircleSpinner
                        className="absolute-centered"
                        message={vm.loadingMessage} />
                </div>
            )}
            
            {!vm.loading && (
                <div className="p-3 flex justify-between gap-8">
                    
                    <div className="w-full">
                        <p className="font-bold">
                            Service Key Name
                        </p>
                        <input
                            className="w-full"
                            type="text"
                            value={vm.serviceApiKey.name}
                            placeholder="My Service API Key"
                            onChange={vm.updateServiceApiKey('name')}/>
                    </div>
                    
                    <div className="w-full">
                        <p className="font-bold">
                            Select an organization <i>(optional)</i>
                        </p>
                        {vm.selectedOrg?.id && (
                            <div className="flex items-center gap-4">
                                <p className="text-sm"><b>Selected:</b> {vm.selectedOrg?.name}</p>
                                <button
                                    className="btn btn-ghost btn-sm"
                                    title="Clear selection"
                                    onClick={() => vm.setSelectedOrg(vm.ORG_NONE)}>
                                    <FaTimes className="text-sm"/>
                                </button>
                            </div>
                        )}
                        <div className="relative flex items-center gap-4">
                            <AutocompleteInput
                                items={[vm.ORG_NONE, ...vm.orgsSearch.orgs || []]}
                                isLoading={vm.orgsSearch.isLoading}
                                managedQuery={vm.orgsSearch.query}
                                setManagedQuery={vm.orgsSearch.setQuery}
                                onChange={vm.setSelectedOrg}/>
                        </div>
                    </div>
                
                </div>
            )}
            
            {!vm.loading && (<>
                
                <RepeatableBlock
                    repeatableVm={vm.allowedDomainsRepeatableViewModel}
                    title="Allowed Domains"
                    keyName="allowed-domain"
                    placeholder="https://api.acme.org" items={vm.allowedDomainsRepeatableViewModel.items}/>
                
                <RepeatableBlock
                    repeatableVm={vm.allowedEndpointsRepeatableViewModel}
                    title="Allowed Endpoints"
                    keyName="allowed-endpoint"
                    placeholder="/acme" />
                
                <RepeatableBlock
                    repeatableVm={vm.resultEmailsRepeatableViewModel}
                    title="Result Email(s)"
                    keyName="result-email"
                    placeholder="notifications@domain.com" />
                
                <RepeatableBlock
                    repeatableVm={vm.resultWebhookUrlsRepeatableViewModel}
                    title="Result Webhook URL(s)"
                    keyName="result-webhook-url"
                    placeholder="https://webhook.site/callback" />
            
            </>)}
            
            {/* <div>
                <pre><code>{JSON.stringify(existingServiceApiKey, null, 4)}</code></pre>
            </div> */}
        
        </Modal>
        
    )
    
}

export default UpsertServiceApiKeyModal
