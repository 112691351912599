import CustomModal, { ModalBody, ModalHeader } from '@/components/shared/CustomModal'
import { useState } from 'react'
import * as actions from '$actions'
import { useSearchParams } from 'react-router-dom'


const CreateTemplateModal = ({
    
    includeSubTabModal,
    setIncludeSubTabModal,
    system,
    
}) => {
    
    const [, setSearchParams] = useSearchParams()
    
    const [newTemplateName, setNewTemplateName] = useState('')
    
    const createNewSubTab = async () => {
        
        try {
            const newTemplate = await actions.addNewTemplate(newTemplateName, system)
            
            setSearchParams(prev => {
                
                prev.set('templateId', newTemplate.id)
                return prev
            })
            
        } catch (err) {
            console.log(err)
        }
        
        setIncludeSubTabModal(false)
        setNewTemplateName('')
        
    }
    
    return (
        
        <CustomModal
            open={includeSubTabModal}
            modalHandler={() => setIncludeSubTabModal(!includeSubTabModal)}>
            
            <ModalHeader>
                New Template
            </ModalHeader>
            
            <ModalBody className="flex gap-4">
                <input
                    type="text"
                    className="w-10/12 input input-bordered"
                    placeholder="Template Name"
                    value={newTemplateName}
                    onChange={e => setNewTemplateName(e.target.value)} />
                <button
                    onClick={() => createNewSubTab()}
                    className="btn btn-primary">
                    Create
                </button>
            </ModalBody>
        </CustomModal>
    )
}

export default CreateTemplateModal