import { useEffect, useMemo, useState, useCallback } from 'react'
import config from '@/config'
import * as jose from 'jose'
import testServiceApiPresets from './testServiceApiPresets'
import { toast } from 'react-toastify'
import useRepeatableViewModel from '@components/shared/Repeatable/RepeatableViewModel'
import { obj2arr } from '@utils'

const contentTypes = [
    'application/json',
    'application/x-www-form-urlencoded',
]

/**
 * Creates a JWT for service authentication
 *
 * @param {GServiceApiKey} service - Service with ID and key
 * @returns {Promise<string>}
 */
const createServiceAuthJwt = async service => {
    
    const alg = 'HS256'
    const secret = new TextEncoder().encode(service.key)
    
    const payload = {
        sub: `parse-service:${service.id}`,  // Identifier for the third party
        aud: 'parse-api', // Intended audience for the token
    }
    
    return await new jose.SignJWT(payload)
        .setProtectedHeader({ alg })
        .setIssuedAt()
        .setSubject(payload.sub)
        .setAudience(payload.aud)
        .setExpirationTime('1h')
        .sign(secret)
    
}

class TestJsonBody {
    
    constructor(data = {}) {
        this.data = data
    }
    
    append(key, value) {
        this.data[key] = value
        return this
    }
    
    toString() {
        return JSON.stringify(this.data)
    }
    
}

const TestServiceApiViewModel = serviceApiKeys => {
    
    const [isLoading, setIsLoading] = useState(false)
    const [service, setService] = useState('')
    const [endpoint, setEndpoint] = useState('')
    const [method, setMethod] = useState('POST')
    const [contentType, setContentType] = useState(contentTypes[1])
    const [token, setToken] = useState('')
    const [file, setFile] = useState('')
    const [fileFieldName, setFileFieldName] = useState('file')
    
    const extraFieldsRepeatableViewModel = useRepeatableViewModel(1)
    const extraHeadersRepeatableViewModel = useRepeatableViewModel(1)
    
    const url = useMemo(() => {
        
        if (!service) return null
        
        let path = endpoint ? `${endpoint}` : ''
        
        if (path && !path.startsWith('/')) path = '/' + path
        
        return `${config.parseApiBaseUrl}/api/v1/services${path}`
        
    }, [service, endpoint])
    
    const isReady = useMemo(() => (
        service?.key?.length > 0 && endpoint?.length > 0 && url?.length > 0
    ), [service, endpoint, url])
    
    const validExtraFields = useMemo(() => (
        extraFieldsRepeatableViewModel.items
            .filter(it => Object.values(it)[0]?.length > 0)
    ), [extraFieldsRepeatableViewModel.items])
    
    const validExtraHeaders = useMemo(() => (
        extraHeadersRepeatableViewModel.items
            .filter(it => Object.values(it)[0]?.length > 0)
    ), [extraHeadersRepeatableViewModel.items])
    
    const onServiceChange = e =>
        setService(serviceApiKeys.find(it => it.id === e.target.value))
    
    const onPresetChange = preset => {
        
        const values = preset.build()
        
        setEndpoint(values.endpoint)
        setMethod(values.method)
        
        extraFieldsRepeatableViewModel.setItems(obj2arr(values.fields || {}))
        extraHeadersRepeatableViewModel.setItems(obj2arr(values.headers || {}))
        
        if (preset.message)
            toast.info(preset.message)
        
    }
    
    const onTestServiceClick = useCallback(async () => {
        
        setIsLoading(true)
        
        const nextToken = await createServiceAuthJwt(service)
        
        setToken(nextToken)
        
        console.log('Testing service', {
            service,
            endpoint,
            token,
            url,
        })
        
        let res
        
        try {
            
            const isJsonBody = contentType === 'application/json'
            const body = isJsonBody ? new TestJsonBody() : new FormData()
            const headers = {}
            
            // Append extra fields
            validExtraFields.forEach(field => {
                
                const key = Object.keys(field)[0]
                const value = field[key]
                
                body.append(key, value)
                
            })
            
            validExtraHeaders.forEach(header => {
                
                const key = Object.keys(header)[0]
                
                headers[key] = header[key]
                
            })
            
            if (file)
                body.append(fileFieldName, file)
            
            if (isJsonBody)
                headers['Content-Type'] = contentType
            
            const options = {
                method,
                headers: {
                    //'Content-Type': contentType,
                    Authorization: `Bearer ${nextToken}`,
                    'X-Service-Key-Id': service.id,
                    ...headers,
                },
            }
            
            console.log('Sending request with options', options)
            
            if (method !== 'GET')
                options.body = isJsonBody ? body.toString() : body
            
            res = await fetch(url, options)
            
            if (!res.ok)
                throw new Error('Failed to fetch data from service')
            
            console.log('Successfully fetched data from service')
            toast.success('Service request successful, see console')
            
            try { console.log(await res.json()) }
            catch (e) { console.log('(no data)') }
            
        } catch (e) {
            
            console.error(`Service authentication failed, status=${res.status}`, e)
            toast.error('Service request failed')
            
            try { console.log(await res.json()) }
            catch (e) { console.log('(no data)') }
            
        }
        
        setIsLoading(false)
        
    }, [
        service,
        endpoint,
        method,
        contentType,
        token,
        file,
        fileFieldName,
        validExtraFields,
        validExtraHeaders,
    ])
    
    useEffect(() => {
        
        // Set initial service, if there is none yet
        if (service || !serviceApiKeys?.length) return
        
        setService(serviceApiKeys[0])
        
    }, [service, serviceApiKeys])
    
    return {
        
        // Constants
        contentTypes,
        
        // State
        isLoading,
        setIsLoading,
        service,
        setService,
        endpoint,
        setEndpoint,
        method,
        setMethod,
        contentType,
        setContentType,
        token,
        setToken,
        file,
        setFile,
        fileFieldName,
        setFileFieldName,
        
        // ViewModels
        extraFieldsRepeatableViewModel,
        extraHeadersRepeatableViewModel,
        
        // Memos
        url,
        isReady,
        validExtraFields,
        validExtraHeaders,
        
        // Methods
        onServiceChange,
        onPresetChange,
        onTestServiceClick,
        
        // External Methods
        createServiceAuthJwt,
        
        // Miscellaneous
        testServiceApiPresets,
        
    }
    
}

export default TestServiceApiViewModel
