import cn from 'classnames'

import './StatBox.css'

const StatBox = ({
    className,
    stat,
    info,
    content,
    ...props
}) => (
    
    <div className={cn('StatBox', className)} {...props}>
        
        {content || (<>
            
            <h5 className="flex justify-center text-center">
                {stat}
            </h5>
            
            <p className="text-xs">{info}</p>
        
        </>)}
    
    </div>
    
)

export default StatBox
