import { memo } from 'react'
import AutoCompleteDropdown from '@components/shared/AutoCompleteDropdown'
import { MdRefresh, MdFileDownload } from 'react-icons/md'

const orderByOptions = [
    'transactionId',
    'organizationId',
    'processingType',
    'pricePerPage',
    'totalPages',
]

const offsetOptions = [0, 10, 20, 30, 40, 50]
const limitOptions = [10, 20, 30, 40, 50, 100, 500, 1000]

const ATRUsageToolbar = ({
    vm,
}) => {
    
    return (
        
        <header className="ATRUsageToolbar flex justify-between items-center w-full">
            
            <section className="flex gap-2">
                
                <label className="form-control">
                    <div className="label py-0 pb-px">
                        <span className="label-text">Organization</span>
                    </div>
                    <div className="relative flex items-center gap-4">
                        <AutoCompleteDropdown
                            items={[vm.ORG_NONE, ...vm.orgsSearch.orgs || []]}
                            query={vm.orgsSearch.query}
                            setQuery={vm.orgsSearch.setQuery}
                            selectedItem={vm.selectedOrg}
                            setSelectedItem={vm.setSelectedOrg}
                            displayValueKey="name"
                            isLoading={vm.orgsSearch.isLoading}
                            onClose={() => vm.orgsSearch.setQuery('')}
                            label="Organization"
                            placeholder="Search organizations..." />
                    </div>
                </label>
                
                <label className="form-control">
                    <div className="label py-0 pl-2">
                        <span className="label-text">Start Date</span>
                    </div>
                    <input
                        className="ml-2 w-auto py-2 px-3 rounded-md border border-base-300 bg-base-200 shadow-sm 
                            focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                        type="date"
                        value={vm.startDate}
                        onChange={e => vm.setStartDate(e.target.value)} />
                </label>
                
                <label className="form-control">
                    <div className="label py-0 pl-2">
                        <span className="label-text">End Date</span>
                    </div>
                    <input
                        className="ml-2 w-auto py-2 px-3 rounded-md border border-base-300 bg-base-200 shadow-sm 
                            focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                        type="date"
                        value={vm.endDate}
                        onChange={e => vm.setEndDate(e.target.value)} />
                </label>
                
                <label className="form-control">
                    <div className="label py-0 pl-2">
                        <span className="label-text">Order By</span>
                    </div>
                    <select
                        className="ml-2 w-auto py-2 px-3 rounded-md border border-base-300 bg-base-200
                            shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                        value={vm.orderBy}
                        onChange={e => vm.setOrderBy(e.target.value)}>
                        {orderByOptions.map(it => (
                            <option key={`order-by-${it}`} value={it}>
                                {it}
                            </option>
                        ))}
                    </select>
                </label>
                
                <label className="form-control">
                    <div className="label py-0 pl-2">
                        <span className="label-text">Offset</span>
                    </div>
                    <select
                        className="ml-2 w-auto min-w-16 py-2 px-3 rounded-md border border-base-300 bg-base-200
                            shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                        value={vm.offset}
                        onChange={e => vm.setOffset(e.target.value)}>
                        {offsetOptions.map(it => (
                            <option key={`offset-${it}`} value={it}>
                                {it}
                            </option>
                        ))}
                    </select>
                </label>
                
                <label className="form-control">
                    <div className="label py-0 pl-2">
                        <span className="label-text">Limit</span>
                    </div>
                    <select
                        className="ml-2 w-auto min-w-16 py-2 px-3 rounded-md border border-base-300 bg-base-200
                            shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                        value={vm.limit}
                        onChange={e => vm.setLimit(e.target.value)}>
                        {limitOptions.map(it => (
                            <option key={`offset-${it}`} value={it}>
                                {it}
                            </option>
                        ))}
                    </select>
                </label>
            
            </section>
            
            <ul className="menu menu-horizontal bg-base-200 rounded-box mt-6">
                <li>
                    <a
                        className="btn btn-secondary btn-square btn-ghost btn-sm"
                        title="Refresh"
                        onClick={vm.fetchBillableEvents}>
                        <MdRefresh className="text-lg" />
                    </a>
                </li>
                <li>
                    <a
                        className="btn btn-secondary btn-square btn-ghost btn-sm"
                        title="Download"
                        onClick={vm.downloadBillableEventsReport}>
                        <MdFileDownload className="text-lg" />
                    </a>
                </li>
            </ul>
        
        </header>
        
    )
    
}

export default memo(ATRUsageToolbar)
