import Logger from '@utils/log'
import { memo, useMemo, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import * as appActions from '@actions'
import * as constants from '@constants'
import { setNamespace } from 'react-wire-persisted'
import useInit from '@hook/useInit'
import useTheme from '@hook/useTheme'
import { useDebug } from '@hook/useDebug'
import { useSubscribeIdentifyUser } from '@utils/logrocket'
import cn from 'classnames'

import Sidebar from '$components/Sidebar'
import Realtime from '@components/shared/Realtime/Realtime'

import '$styles/index.css'

const log = new Logger('DashboardApp')

setNamespace(constants.NS)

const DashboardApp = ({
    children,
    excludeDashboardSidebar = false,
} = {}) => {
    
    const navigate = useNavigate()
    
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false)
    
    const user = useWireValue(store.user)
    const isFetchingUser = useWireValue(store.isFetchingUser)
    
    const extraStyles = useMemo(() => {
        
        const styles = {}
        
        if (excludeDashboardSidebar)
            styles['--sidebar-width'] = 0
        
        return styles
        
    }, [excludeDashboardSidebar])
    
    useEffect(() => {
        
        if (isFetchingUser) return
        
        appActions.fetchProfile()
        
        // eslint-disable-next-line no-restricted-globals
        document.getElementById('hubspot-messages-iframe-container')?.style
            ?.setProperty('display', 'none', 'important')
        
        return () => {
            // eslint-disable-next-line no-restricted-globals
            document.getElementById('hubspot-messages-iframe-container')?.style
                ?.setProperty('display', '')
        }
        
    }, [])
    
    useEffect(() => {
        
        const t = setTimeout(() => {
            
            if (user) return
            
            if (localStorage.getItem('jwt')?.length) return
            
            log.w('Dashboard not authorized')
            navigate('/signin')
            
        }, 1000)
        
        return () => clearTimeout(t)
        
    }, [user])
    
    useInit()
    useSubscribeIdentifyUser()
    useTheme()
    useDebug()
    
    return (
        
        <div
            className={cn('Dashboard', {
                'sidebar-collapsed': sidebarCollapsed,
            })}
            style={extraStyles}>
            
            {!excludeDashboardSidebar && (
                <Sidebar
                    collapsed={sidebarCollapsed}
                    setCollapsed={setSidebarCollapsed} />
            )}
            
            <main className={cn({ 'is-child-route': excludeDashboardSidebar })}>
                {children}
            </main>
            
            <Realtime />
        
        </div>
        
    )
    
}

export default memo(DashboardApp)
