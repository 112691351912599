import { FaPlus, FaMinus, FaAngleDown } from 'react-icons/fa'
import useTestServiceApiViewModel from './TestServiceApiViewModel'
import { v4 as uuidV4 } from 'uuid'
import Repeatable from '@components/shared/Repeatable/Repeatable'
import { FiHash } from 'react-icons/fi'

const TestServiceApi = ({
    serviceApiKeys,
}) => {
    
    const vm = useTestServiceApiViewModel(serviceApiKeys)
    
    return (<>
        
        <div className="TestServiceApi">
            
            <header className="flex items-baseline content-baseline gap-8 mb-1">
                <h5>Test Service API</h5>
                <div className="flex justify-end items-center content-center gap-2">
                    <div className="dropdown dropdown-end">
                        <div className="btn m-1" tabIndex={0} role="button">
                            <span>Presets</span>
                            <span className="pl-1 ml">
                                <FaAngleDown className="text-lg" />
                            </span>
                        </div>
                        <ul
                            className="dropdown-content menu bg-base-100 rounded-box w-52 p-2 shadow"
                            tabIndex={0}>
                            {vm.testServiceApiPresets.map(it => (
                                <li key={it.id}>
                                    <a onClick={() => vm.onPresetChange(it)}>
                                        {it.label}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </header>
            
            <table className="table table-auto !table-sm w-fit max-w-screen-lg">
                <tbody>
                    <tr>
                        <th>Service:</th>
                        <td>
                            <select
                                className="select select-bordered select-sm"
                                value={vm.service.id}
                                onChange={vm.onServiceChange}>
                                {serviceApiKeys?.map(it => (
                                    <option key={it.id} value={it.id}>
                                        {it.name}{it.organization?.name ? ` / ${it.organization?.name}` : ''}
                                    </option>
                                ))}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>Endpoint:</th>
                        <td>
                            <input
                                className="input input-bordered input-sm"
                                type="text"
                                placeholder="demo"
                                value={vm.endpoint}
                                onChange={e => vm.setEndpoint(e.target.value)}/>
                        </td>
                    </tr>
                    <tr>
                        <th>Method:</th>
                        <td>
                            <select
                                className="select select-bordered select-sm"
                                value={vm.method}
                                onChange={e => vm.setMethod(e.target.value)}>
                                <option value="GET">GET</option>
                                <option value="POST">POST</option>
                                <option value="PUT">PUT</option>
                                <option value="DELETE">DELETE</option>
                                <option value="PATCH">PATCH</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>Content Type:</th>
                        <td>
                            <select
                                className="select select-bordered select-sm"
                                value={vm.contentType}
                                onChange={e => vm.setContentType(e.target.value)}>
                                {vm.contentTypes.map(it => (
                                    <option key={it} value={it}>{it}</option>
                                ))}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>File:</th>
                        <td>
                            <input
                                className="input input-bordered input-sm"
                                type="text"
                                placeholder="file"
                                value={vm.fileFieldName}
                                onChange={e => vm.setFileFieldName(e.target.value)}/>
                            <input
                                className="file-input input-sm"
                                type="file"
                                onChange={e => vm.setFile(e.target.files?.[0])}/>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <div className="w-auto flex !justify-end items-center content-center gap-4">
                                <div>Extra Fields</div>
                                <div className="w-auto">
                                    <button
                                        className="btn btn-sm btn-square btn-secondary"
                                        onClick={vm.extraFieldsRepeatableViewModel.onAddItemClick}>
                                        <FaPlus className="!ml-0"/>
                                    </button>
                                </div>
                            </div>
                        </th>
                        <td>
                            <Repeatable viewModel={vm.extraFieldsRepeatableViewModel}>
                                {(key, value, i, onKeyChange, onValueChange) => (
                                    <div key={`extra-field-${i}`} className="flex items-center content-center gap-2">
                                        <input
                                            className="input input-bordered input-sm"
                                            type="text"
                                            placeholder="key"
                                            value={key}
                                            onChange={e => onKeyChange(i, e)} />
                                        <input
                                            className="input input-bordered input-sm min-w-80"
                                            type="text"
                                            placeholder="value"
                                            value={value}
                                            onChange={e => onValueChange(i, e)} />
                                        <button
                                            className="btn btn-sm btn-square btn-warning"
                                            onClick={() => vm.extraFieldsRepeatableViewModel.onRemoveItemClick(i)}>
                                            <FaMinus className="!ml-0"/>
                                        </button>
                                        <button
                                            className="btn btn-sm btn-square btn-secondary"
                                            title="Generate a random UUID"
                                            onClick={() => onValueChange(i, {
                                                target: {
                                                    value: `PARSE_${uuidV4()}`,
                                                },
                                            })}>
                                            <FiHash className="!ml-0"/>
                                        </button>
                                    </div>
                                )}
                            </Repeatable>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <div className="w-auto flex !justify-end items-center content-center gap-4">
                                <div>Extra Headers</div>
                                <div>
                                    <button
                                        className="btn btn-sm btn-square btn-secondary"
                                        onClick={vm.extraHeadersRepeatableViewModel.onAddItemClick}>
                                        <FaPlus className="!ml-0"/>
                                    </button>
                                </div>
                            </div>
                        </th>
                        <td>
                            <Repeatable viewModel={vm.extraHeadersRepeatableViewModel}>
                                {(key, value, i, onKeyChange, onValueChange) => (
                                    <div key={`extra-field-${i}`} className="flex items-center content-center gap-2">
                                        <input
                                            className="input input-bordered input-sm"
                                            type="text"
                                            placeholder="key"
                                            value={key}
                                            onChange={e => onKeyChange(i, e)} />
                                        <input
                                            className="input input-bordered input-sm min-w-80"
                                            type="text"
                                            placeholder="value"
                                            value={value}
                                            onChange={e => onValueChange(i, e)} />
                                        <button
                                            className="btn btn-sm btn-square btn-warning"
                                            onClick={() => vm.extraHeadersRepeatableViewModel.onRemoveItemClick(i)}>
                                            <FaMinus className="!ml-0"/>
                                        </button>
                                        <button
                                            className="btn btn-sm btn-square btn-secondary"
                                            title="Generate a random UUID"
                                            onClick={() => onValueChange(i, { target: { value: uuidV4() } })}>
                                            <FiHash className="!ml-0"/>
                                        </button>
                                    </div>
                                )}
                            </Repeatable>
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <div className="mt-4">
                <button
                    className="btn btn-xl btn-success text-3xl w-1/4"
                    disabled={!vm.isReady || vm.isLoading}
                    onClick={vm.onTestServiceClick}>
                    {vm.isLoading && <span className="loading loading-spinner" />}
                    <span>TEST SERVICE</span>
                </button>
            </div>
            
            <table className="table table-auto w-fit max-w-fit table-xs table-zebra mt-8">
                <caption className="text-left">Summary</caption>
                <tbody>
                    <tr>
                        <td><b>Key:</b></td>
                        <td>{vm.service?.key}</td>
                    </tr>
                    <tr>
                        <td><b>Endpoint:</b></td>
                        <td>{vm.endpoint}</td>
                    </tr>
                    <tr>
                        <td><b>Target:</b></td>
                        <td>{vm.url}</td>
                    </tr>
                    <tr>
                        <td><b>Token:</b></td>
                        <td><span className="break-all">{vm.token}</span></td>
                    </tr>
                    <tr>
                        <td><b>File:</b></td>
                        <td>&quot;{vm.fileFieldName ?? 'N/A'}&quot; = {vm.file?.name ?? 'N/A'}</td>
                    </tr>
                    <tr>
                        <td><b>Extra Fields:</b></td>
                        <td>
                            <ul className="list-disc pl-5">
                                {vm.validExtraFields.map(it => {
                                    
                                    const key = Object.keys(it)[0]
                                    const value = it[key]
                                    
                                    return (
                                        <li key={key}><b>{key}:</b> {value}</li>
                                    )
                                    
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td><b>Extra Headers:</b></td>
                        <td>
                            <ul className="list-disc pl-5">
                                {vm.validExtraHeaders.map(it => {
                                    
                                    const key = Object.keys(it)[0]
                                    const value = it[key]
                                    
                                    return (
                                        <li key={key}><b>{key}:</b> {value}</li>
                                    )
                                    
                                })}
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        
        </div>
    
    </>)
    
}

export default TestServiceApi
