import { splitPdfPageImages } from '@utils/pdfs'
import { stripExtension, appendFileName } from '@utils/files'
import { nanoid } from 'nanoid'

/**
 * Renames the file name of a split PDF page image with a unique ID and the correct extension
 * 
 * @param {File} file - The original PDF file to use for naming the pages
 * @param {SplitPdfPageImagesResultPayload[]} payloads
 * @returns {Promise<SplitPdfPageImagesResultPayloadWithDataUri[]>}
 */
const renameSplitPdfPageImagesResults = async (file, payloads) => {
    
    const results = payloads.map(payload => ({
        ...payload,
        fileName: appendFileName(
            file.name,
            null,
            `-${stripExtension(payload.fileName)}-${nanoid()}`,
            '.png',
        ),
    }))
    
    return results.sort((a, b) => a.pageNumber - b.pageNumber)
    
}

/**
 * Calculates the time remaining label for the batch upload dialog
 * 
 * @param {{seconds: number, minutes: number, hours: number}} timeRemaining - The time remaining in seconds
 * @returns {string} The time remaining label
 */
export const calculateTimeRemainingLabel = timeRemaining => {
    
    const {
        seconds,
        minutes,
        hours,
    } = timeRemaining
    
    const largestUnit = Math.max(
        seconds,
        minutes,
        hours)
    
    if (largestUnit === seconds)
        return `Approx. ${seconds} seconds remaining`
    
    if (largestUnit === minutes)
        return minutes <= 1
            ? `Less than ${minutes} minutes remaining`
            : `Approx. ${minutes} minutes remaining`
    
    return `Approx. ${hours} hours remaining`
    
}

/**
 * 
 * @param {File} file
 * @param {Function} onUpdateProgress
 * @returns {Promise<SplitPdfPageImagesResultPayloadWithDataUri[]>}
 */
export const createPdfPages = async (file, onUpdateProgress) => {
    
    try  {
        
        /** @type {SplitPdfPageImagesResult} */
        const { results, stats } = await splitPdfPageImages(
            file,
            value => onUpdateProgress(null, value),
            totalPages => {
                console.log(`Creating ${totalPages} PDF pages`)
                onUpdateProgress(totalPages, null)
            },
            () => {
                // Reset the progress bar again so we can show file upload progress
                onUpdateProgress()
            },
        )
        
        console.log(`[stats] numPages: ${stats.numPages}`)
        console.log(`[stats] avgTimePerPage: ${stats.avgTimePerPageSeconds.toFixed(2)} seconds`)
        console.log(`[stats] total duration: ${stats.totalDurationSeconds.toFixed(2)} seconds`)
        
        return renameSplitPdfPageImagesResults(file, results)
        
    } catch (e) {
        
        console.error('createPdfPages', e)
        return null
        
    }
    
}
