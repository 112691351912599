import GlobalUploadsPanelViewModel from './GlobalUploadsPanelViewModel'
import GUPFilesTable from './widgets/GUPFilesTable'
import cn from 'classnames'

import { MdInfoOutline } from 'react-icons/md'
import GUPDebug from './widgets/GUPDebug'
import GUPUploadsComplete from '@components/shared/Dialogs/GlobalUploadsPanel/widgets/GUPUploadsComplete'
import GUPHeader from '@components/shared/Dialogs/GlobalUploadsPanel/widgets/GUPHeader'

// On-screen debug panel, useful for debugging the GlobalUploadsPanel
const showDebugPanel = false

const GlobalUploadsPanel = () => {
    
    const vm = GlobalUploadsPanelViewModel()
    
    // Don't show the panel if the user or org aren't set, or it appears on the login screen
    if (!vm.user?.id || !vm.currentOrganization?.id)
        return null
    
    if (!vm.allUploadsComplete && !vm.hasPendingUploadFiles)
        return showDebugPanel ? <GUPDebug vm={vm} /> : null
    
    return (<>
        
        {showDebugPanel && <GUPDebug vm={vm} />}
        
        <div className={cn(
            'GlobalUploadsPanel fixed bottom-2 right-2 w-[385px] flex flex-col overflow-auto',
            'bg-base-200 bg-opacity-[99%] text-base-content rounded-lg shadow-lg',
            'transition-all duration-300 ease-in-out', {
                'opacity-90 hover:opacity-100': !vm.globalUploadsPanelExpanded,
            })}>
            
            <GUPHeader
                allUploadsComplete={vm.allUploadsComplete}
                stagedUploadFiles={vm.stagedUploadFiles}
                globalUploadsPanelExpanded={vm.globalUploadsPanelExpanded}
                toggleGlobalUploadsPanelExpanded={vm.toggleGlobalUploadsPanelExpanded}
                pendingUploadsTotalProgress={vm.pendingUploadsTotalProgress}
                pendingUploadsFailed={vm.pendingUploadsFailed}
                resetGlobalUploadsPanel={vm.resetGlobalUploadsPanel} />
            
            {vm.allUploadsComplete && (
                <GUPUploadsComplete
                    collectionId={vm.stagedUploadOptions?.collectionId}
                    pendingUploads={vm.pendingUploads}
                    pendingUploadsFailed={vm.pendingUploadsFailed}
                    onCollectionLinkClick={() => vm.resetGlobalUploadsPanel()} />)}
            
            {!vm.allUploadsComplete && (
                <div className="flex justify-between items-center content-center gap-2 w-full px-4 py-1 bg-accent/20">
                    <div className="text-sm">
                        {vm.timeRemainingLabel}
                    </div>
                    <button
                        className="btn btn-sm btn-square btn-ghost opacity-70 hover:opacity-100"
                        onClick={vm.showUploadPanelInfo}>
                        <MdInfoOutline className="text-xl text-base-content" />
                    </button>
                </div>
            )}
            
            {/* If the panel is expanded, show the table of uploads */}
            {!vm.allUploadsComplete && (
                <div className={cn('flex flex-col gap-4 overflow-auto',
                    'transition-all duration-300 ease-in-out', {
                        'h-[270px] my-2': vm.globalUploadsPanelExpanded,
                        'h-px': !vm.globalUploadsPanelExpanded,
                    })}>
                    {vm.globalUploadsPanelExpanded && (
                        <GUPFilesTable
                            vm={vm}
                            stagedUploadItems={vm.pendingUploadsSortedByState} />
                    )}
                    {/* {vm.globalUploadsPanelExpanded && (<>
                        
                        <label className="px-4 text-xs font-bold">PROCESSING</label>
                        <GUPFilesTable
                            vm={vm}
                            stagedUploadItems={vm.pendingUploadsProcessing} />
                        
                        <label className="px-4 text-xs font-bold">UPLOADING</label>
                        <GUPFilesTable
                            vm={vm}
                            stagedUploadItems={vm.pendingUploadsUploading} />
                        
                        <label className="px-4 text-xs font-bold">QUEUED</label>
                        <GUPFilesTable
                            vm={vm}
                            stagedUploadItems={vm.pendingUploadsQueued} />
                    </>)} */}
                </div>
            )}
        
        </div>
    
    </>)
    
}

export default GlobalUploadsPanel
