import { Suspense } from 'react'

const CenteredSuspense = ({ children = null }) => (
    
    <Suspense fallback={
        <div className="absolute-centered">
            &nbsp;
        </div>
    }>
        
        {children}
    
    </Suspense>
    
)

export default CenteredSuspense
