import { useEffect } from 'react'
import * as store from '@store'
import { useWireValue } from '@forminator/react-wire'
import { useWireState } from '@forminator/react-wire'
import { NOTIFICATION_TARGET_ORGANIZATION, RT_EVENT_TYPE_UPDATE_CREDITS } from '@/constants/realtime'
import { toast } from 'react-toastify'

export const useRealtimeUpdateCredits = () => {
    const notifications = useWireValue(store.notifications)
    const [credits, setCredits] = useWireState(store.credits)
    const remoteConfig = useWireValue(store.remoteConfig)
    
    useEffect(() => {
        
        if (!remoteConfig?.realtimeEnabled) return
        
        const latestNotification = notifications
            .filter(
                notification => notification.message.data.type === RT_EVENT_TYPE_UPDATE_CREDITS,
            )
            .sort((a, b) => new Date(b.message.data.data.createdAt) - new Date(a.message.data.data.createdAt))[0]
        
        if (!latestNotification) return
        
        const currCredits ={ ...credits }
        const { credits: nextCredits, target } = latestNotification.message.data.data
        
        setCredits({
            ...currCredits,
            ...nextCredits,
        })
        
        if (
            nextCredits.organization !== currCredits.organization
                && target === NOTIFICATION_TARGET_ORGANIZATION
        ) {
            toast.success(`Organization credits updated: $${nextCredits.organization}`)
        }
    }, [notifications])
}
