import { Navigate } from 'react-router-dom'

const unauthenticatedPaths = [
    '/setpassword',
    '/setorganizations',
]

const PrivateRoute = ({ children = null }) => {
    
    if (!unauthenticatedPaths.includes(location.pathname))
        return children
    
    return localStorage.getItem('jwt')
        ? children
        : <Navigate to="/signin" />
    
}

export default PrivateRoute
