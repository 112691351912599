import { useCallback } from 'react'
import { relativeTime } from '@/utils/date'
import { FaPlus, FaTrash } from 'react-icons/fa'
import UpsertServiceApiKeyModal from '$components/Modals/UpsertServiceApiKeyModal/UpsertServiceApiKeyModal'
import CopyToClipboardButton from '$components/CopyToClipboardButton'
import cn from 'classnames'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'

const ServiceApiKeys = ({
    vm,
}) => {
    
    const createClipboardValue = useCallback(service => JSON.stringify({
        id: service.id,
        key: service.key,
    }, null, 4), [vm])
    
    return (<>
        
        <div className="ServiceApiKeys">
            
            <header className="flex items-baseline justify-between content-baseline mb-1">
                <h5>Service API Keys</h5>
                <div className="flex items-center content-center justify-end gap-2">
                    <input
                        type="text"
                        autoComplete="off"
                        value={vm.query}
                        placeholder="Filter api keys..."
                        onKeyUp={e => e.key === 'Escape' && vm.setApiKeysQuery('')}
                        onChange={e => vm.setApiKeysQuery(e.target.value?.toLowerCase() ?? '')}/>
                    <button
                        className="btn btn-success text-3xl"
                        onClick={() => vm.setModalCreateServiceApiKeyOpen(true)}>
                        <FaPlus className="inline text-base" />
                    </button>
                </div>
            </header>
            
            {vm.error && (
                <div className="bg-error text-error-content">
                    <p>Failed to fetch service API keys.</p>
                    <p>Error: {vm.error}</p>
                </div>
            )}
            
            {vm.loading && <p>Fetching service API keys...</p>}
            
            {!vm.error && !vm.loading && (
                <table className="w-full">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Organization</th>
                            <th>Key</th>
                            <th>Created</th>
                            <th>Updated</th>
                            <th>Allowed Domains</th>
                            <th>Allowed Endpoints</th>
                            <th>Result Email</th>
                            <th>Result Webhook URL</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vm.filteredApiKeys.map(it => (
                            <tr key={it.id} className={cn({ 'tr-muted': !it.enabled })}>
                                <td>{it.name}</td>
                                <td>{it.organization?.name ?? '-'}</td>
                                <td>
                                    <div className="flex items-center content-center gap-2">
                                        <code
                                            className="cursor-pointer text-sm break-all"
                                            title={vm.unmaskedKeys[it.id] ? '' : 'Click to reveal'}
                                            onClick={() => vm.onToggleMask(it.id)}>
                                            {vm.unmaskedKeys[it.id] ? it.key : '****'}
                                        </code>
                                        <CopyToClipboardButton
                                            className="ml-3"
                                            value={createClipboardValue(it)}
                                            title="Copy key ID and secret to the clipboard" />
                                    </div>
                                </td>
                                <td>{relativeTime(it.createdAt)}</td>
                                <td>{relativeTime(it.updatedAt)}</td>
                                <td>{it.allowedDomains?.length > 0 ? it.allowedDomains?.join(', ') : '-'}</td>
                                <td>{it.allowedEndpoints?.length ? it.allowedEndpoints?.join(', ') : '-'}</td>
                                <td>{it.resultEmails?.length > 0 ? it.resultEmails?.join(', ') : '-'}</td>
                                <td>{it.resultWebhookUrls?.length > 0 ? it.resultWebhookUrls?.join(', ') : '-'}</td>
                                <td>
                                    <div className="flex items-center content-center gap-2">
                                        <button
                                            className="btn btn-sm btn-square btn-ghost"
                                            disabled={vm.deletingKey === it.id}
                                            onClick={() => vm.onToggleEnabledClick(it.id)}>
                                            {it.enabled ? <FaEye /> : <FaEyeSlash />}
                                        </button>
                                        <button
                                            className="btn btn-sm btn-square btn-ghost"
                                            disabled={vm.deletingKey === it.id}
                                            onClick={() => vm.onEditClick(it.id)}>
                                            <MdEdit />
                                        </button>
                                        <button
                                            className="btn btn-sm btn-square btn-ghost"
                                            disabled={vm.deletingKey === it.id}
                                            onClick={() => vm.onDeleteClick(it.id)}>
                                            <FaTrash className="text-red-400"/>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        
        </div>
        
        <UpsertServiceApiKeyModal
            open={vm.modalCreateServiceApiKeyOpen}
            setOpen={vm.setModalCreateServiceApiKeyOpen}
            existingServiceApiKey={vm.editingKey} />
    
    </>)
    
}

export default ServiceApiKeys
