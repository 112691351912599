import useDatabaseBrowserViewModel from './DatabaseBrowserViewModel'
import DatabaseBrowserRow from '$routes/DatabaseManager/widgets/DatabaseBrowser/DatabaseBrowserRow'

const DatabaseBrowser = () => {
    
    const vm = useDatabaseBrowserViewModel()
    
    return (
        
        <div className="DatabaseBrowser">
            
            <header className="w-full flex items-baseline justify-between content-baseline">
                
                <div className="flex justify-between items-baseline content-baseline gap-4 w-full">
                    <h5 className="text-xl text-dashboard-primary">
                        Database Browser
                    </h5>
                    <div className="flex justify-end items-center gap-2">
                        <select
                            className="select select-bordered select-sm"
                            value={vm.table ?? ''}
                            onChange={e => vm.setTable(e.target.value)}>
                            {vm.tables?.map(it => (
                                <option key={it} value={it}>
                                    {it}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            
            </header>
            
            <div className="flex flex-col gap-4">
                
                {vm.error && (
                    <div className="alert alert-danger">
                        {vm.error?.message}
                    </div>
                )}
                
                {vm.isLoading && (
                    <div className="flex justify-center p-4 text-center">
                        <div className="loading loading-spinner" />
                    </div>
                )}
                
                {!vm.error && !vm.isLoading && !vm.tableRows?.length && (
                    <div className="flex justify-center p-4 text-center">
                        <p>No results found</p>
                    </div>
                )}
                
                {!vm.error && !vm.isLoading && (
                    <table className="table table-auto table-xs table-pin-rows table-zebra w-full">
                        <thead>
                            <tr>
                                {vm.tableRows?.fields?.map(it => (
                                    <th key={it.columnID} className="text-xs normal-case">
                                        {it.name}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {vm.tableRows?.rows?.map(row => (
                                <tr key={row.id}>
                                    {vm.tableRows?.fields?.map(col => (
                                        /* <td key={`${col.columnID}-${row.id}`}>
                                            {row[col.name]}
                                        </td> */
                                        <DatabaseBrowserRow row={row} col={col} />
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            
            </div>
        
        </div>
        
    )
    
}

export default DatabaseBrowser
