import { useRef, useEffect, useCallback, useState, useImperativeHandle, forwardRef } from 'react'
import { MdClose } from 'react-icons/md'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import cn from 'classnames'
import { 
    RT_EVENT_TYPE_AUTO_CHAIN, 
    RT_EVENT_TYPE_DOCUMENT_PROCESS, 
    RT_EVENT_TYPE_MESSAGE_PLAIN,
} from '@/constants/realtime'
import * as actions from '@actions'

dayjs.extend(relativeTime)

const NOTIFICATION_TITLES = {
    [RT_EVENT_TYPE_MESSAGE_PLAIN]: {
        success: 'User Notification',
    },
    [RT_EVENT_TYPE_DOCUMENT_PROCESS]: {
        success: 'Document Processed Successfully',
        failure: 'Document Processing Failed',
    },
    [RT_EVENT_TYPE_AUTO_CHAIN]: {
        success: 'Auto Chain Completed Successfully',
        failure: 'Auto Chain Processing Failed',
    },
}

const getNotificationTitle = (type, success) => {
    const result = NOTIFICATION_TITLES[type]
    
    if (!result) {
        return success ? 'Action Completed Successfully' : 'Action Failed'
    }
    
    if (type === 'message:plain') {
        return result.success
    }
    
    return success ? result.success : result.failure
}

const NotificationCenterItem = forwardRef(({ notification }, ref) => {
    const elementRef = useRef()
    const [isDismissing, setIsDismissing] = useState(false)
    const {
        message: {
            data: { 
                data: { message }, 
                type, 
                createdAt, 
                success,
                seenByTarget,
                seenLocally,
                id,
            },
        },
    } = notification
    
    const formattedTime = dayjs(createdAt).fromNow()
    const title = getNotificationTitle(type, success)
    
    const dismissNotification = useCallback(() => {
        setIsDismissing(true)
        
        setTimeout(() => {
            actions.dismissNotification(id, type)
        }, 300)
    }, [id, type])
    
    useImperativeHandle(ref, () => ({
        setIsDismissing: () => setIsDismissing(true), 
    }))
    
    const isDropdownOpen = () => {
        // eslint-disable-next-line no-restricted-globals
        return document.getElementById('NotificationCenter')?.classList.contains('dropdown-open')
    }
    
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !seenByTarget && !seenLocally && isDropdownOpen()) {
                    actions.markNotificationAsSeen(id)
                }
            },
            {
                threshold: 0.5,
            },
        )
        
        if (elementRef.current) {
            observer.observe(elementRef.current)
        }
        
        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current)
            }
        }
    }, [id, seenByTarget, seenLocally, isDropdownOpen])
    
    return (
        <li
            ref={elementRef}
            key={id}
            className={cn(
                'notification-item', 
                { 'opacity-50': seenByTarget },
                { 'fade-out': isDismissing },
            )}>
            <div className="flex-shrink-0 mr-4">
                <div className={cn('icon-wrapper',
                    success ? 'bg-success' : 'bg-error',
                )}>
                    <span>
                        {success ? '✓' : '!'}
                    </span>
                </div>
            </div>
            <div className="flex-1">
                <h4>{title}</h4>
                <p>{message}</p>
                <span>{formattedTime}</span>
            </div>
            <button
                onClick={() => dismissNotification()}>
                <MdClose />
            </button>
        </li>
    )
})

export default NotificationCenterItem
