import { useState, useEffect, useCallback } from 'react'
import { useWireState } from '@forminator/react-wire'
import * as store from '$store'
import * as appActions from '@actions'
import useCheckableItems from '@hook/useCheckableItems'
import { toast } from 'react-toastify'
import cn from 'classnames'

import Modal from '$components/Modal'
import Checkbox from '$components/Forms/Checkbox'

const ManageRecordLabelsBaseModal = ({
    className,
    recordType,
    record,
    recordLabel,
    recordNameKey,
    storeKey,
    fetchRecordsFn,
    setLabelsToRecordFn,
    ...props
}) => {
    
    const [labels, setLabels] = useState([])
    const [recordId, setRecordId] = useState(null)
    
    const [open, setOpen] = useWireState(store[storeKey])
    
    const {
        checked,
        allChecked,
        someChecked,
        hasChecked,
        setChecked,
        toggleChecked,
        toggleAllChecked,
    } = useCheckableItems(labels)
    
    const onSaveAssociatedLabelsClick = useCallback(async () => {
        
        try {
            
            const res = await setLabelsToRecordFn(record.id, checked)
            
            await fetchRecordsFn({ id: record.id })
            
            console.log(res)
            
        } catch (e) {
            
            console.error('onSaveAssociatedLabelsClick', e)
            toast.error('Failed to save labels')
            
        }
        
        toggleAllChecked(false)
        
    }, [checked])
    
    useEffect(() => {
        
        if (open && !labels.length)
            appActions.fetchLabels(recordType)
                .then(it => setLabels(it.data))
                .catch(e => {
                    console.error('ManageRecordLabelsBaseModel: failed to fetch labels', e)
                    toast.error('Failed to fetch labels')
                })
        
    }, [open, labels, recordType])
    
    useEffect(() => {
        
        if (!record || !labels.length || recordId === record?.id) {
            
            if (!open) {
                // Clear labels & checked when unloading, otherwise dialogs for
                // other records will show the same checked states
                if (labels?.length) setLabels([])
                if (checked?.length) setChecked([], true)
                setRecordId(null)
            }
            
            return
            
        }
        
        // When the modal initially loads, if the record has no labels yet,
        // we assume all labels should apply; otherwise, set the existing labels to checked
        if (!record.labels?.length)
            toggleAllChecked(true)
        else
            setChecked(record.labels.map(it => it.id), true)
        
        setRecordId(record.id)
        
    }, [open, record, recordId, labels, checked])
    
    if (!record || !labels.length) return null
    
    return (
        
        <Modal
            className={cn(className, 'ManageRecordLabelsBaseModal overflow-x-auto max-w-[min(650px,100vw)]')}
            style={{ maxHeight: '90%' }}
            open={open}
            setOpen={setOpen}
            title={`Manage ${recordLabel} Labels`}
            subtitle={`${checked?.length ?? 0} selected`}
            actionLabel="SAVE LABEL ASSOCIATIONS"
            actionOnClick={onSaveAssociatedLabelsClick}
            {...props}>
            
            <div className="mb-4">
                    Assign labels to {recordLabel.toLowerCase()} <b>{record[recordNameKey]}</b>.
                <br />
                <i>By default, all labels are shown for a {recordLabel.toLowerCase()} unless specified here.</i>
            </div>
            
            <div className="mb-4">
                <label className="flex items-center content-center gap-4 pl-2">
                    <Checkbox
                        className="mx-3"
                        checked={allChecked}
                        indeterminate={someChecked}
                        onChange={toggleAllChecked} />
                    <span>Use all labels</span>
                </label>
            </div>
            
            <ul className="h-[calc(100vh-498px)] overflow-y-auto">
                {labels.map((it, idx) => (
                    <li key={it.id} className={cn('pl-2 rounded', {
                        'bg-base-200': idx % 2 === 0, 
                    })}>
                        <label className="flex items-center content-center gap-4 my-1">
                            <div className="flex items-center content-center justify-end gap-4">
                                <Checkbox
                                    className="ml-2"
                                    disabled={allChecked}
                                    checked={hasChecked(it.id)}
                                    onChange={() => toggleChecked(it.id)} />
                                
                                <span
                                    className="w-8 h-8"
                                    style={{ backgroundColor: it.color ?? 'blue' }} />
                            </div>
                            <span className="text-base">{it.label}</span>
                        </label>
                    </li>
                ))}
            </ul>
        
        </Modal>
        
    )
    
}

export default ManageRecordLabelsBaseModal
