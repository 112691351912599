/**
 * Strips the extension from a file name
 * @param {string} fileName - The file name to strip the extension from
 * @returns {string}
 */
export const stripExtension = fileName => {
    
    if (!fileName?.length)
        throw new Error('Invalid file name')
    
    if (!fileName.includes('.'))
        return fileName
    
    // If the total number of '.' is one, and the file name starts with a '.', then return fileName
    if (fileName.split('.').length === 2 && fileName.startsWith('.'))
        return fileName
    
    return fileName.substring(0, fileName.lastIndexOf('.'))
    
}

/**
 * Appends a prefix and suffix to a file name
 * 
 * @param {string} fileName - The original file name
 * @param {string|null} [prefix] - The prefix to add
 * @param {string|null} [suffix] - The suffix to add
 * @param {boolean|string} [withExtension=true] - Whether to include the extension in the new file name
 * @returns {string} The new file name
 */
export const appendFileName = (fileName, prefix, suffix, withExtension = true) => {
    
    if (!fileName?.length)
        throw new Error('Invalid file name')
    
    if (!fileName.includes('.'))
        throw new Error('Invalid file name - missing extension')
    
    const ext = fileName.substring(fileName.lastIndexOf('.'))
    const baseName = fileName.substring(0, fileName.length - ext.length)
    const nextExt = withExtension === true ? ext : (withExtension || '')
    
    return `${prefix ?? ''}${baseName}${suffix ?? ''}${nextExt}`
    
}

/**
 * Converts an image data URI to a file blob
 * 
 * @param {string} imageDataUri - The image data URI
 * @returns {Promise<Blob>}
 */
export const imageDataUriToFileBlob = async imageDataUri => {
    
    const res = await fetch(imageDataUri)
    
    return await res.blob()
    
}

/**
 * Converts an array of image data URIs to a FormData object
 * 
 * @param {UriWithFileName[]} imageDataUris - The array of image data URIs
 * @returns {Promise<FormData|{blob: Blob, fileName: string}[]>}
 */
export const imageDataUrisToFileBlobs = async imageDataUris => {
    
    return await Promise.all(imageDataUris.map(it => {
        return imageDataUriToFileBlob(it.uri)
            .then(res => ({ blob: res, fileName: it.fileName }))
    }))
    
}

export const blobToDataURI = blob => new Promise((resolve, reject) => {
    
    const reader = new FileReader()
    
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = () => reject(new Error('Failed to convert blob to Data URI'))
    
    reader.readAsDataURL(blob)
    
})

export const formatBytesWithUnits = bytes => {
    
    const units = ['B', 'KB', 'MB', 'GB', 'TB']
    
    if (bytes === 0) return '0 B'
    
    const exponent = Math.min(Math.floor(Math.log(bytes) / Math.log(1024)), units.length - 1)
    const value = (bytes / Math.pow(1024, exponent)).toFixed(2)
    
    return `${value} ${units[exponent]}`
    
}
